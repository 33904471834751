import {min} from '@acng/frontend-bounty';
import {validator, INTEGER} from '@acng/frontend-bounty/typeguard';
import {Gallery} from 'acng/core/model/gallery.js';
import {inject} from 'acng/core/service/ng.js';
import {FSK_LEVEL} from 'acng/userPool/fsk.js';

/**
 * @typedef MovieData
 *
 * @property {number} id
 * @property {number} fsk
 * @property {number} filesize
 * @property {number} playtime Playtime in seconds
 * @property {number} width Pixel width
 * @property {number} height Pixel height
 * @property {number} [stream_id] DEPRECATED
 */

/**
 * @implements {MovieData}
 */
export class Movie extends Gallery {
  /**
   * @param {MovieData & import('acng/core/model/gallery.js').GalleryData} data
   */
  constructor(data) {
    super(data);

    ASSERT: {
      const get = validator('MovieData', data);

      this.id = get('id', INTEGER);
      this.width = get('width', INTEGER);
      this.height = get('height', INTEGER);
      this.playtime = get('playtime', INTEGER);
      this.filesize = get('filesize', INTEGER);
      this.fsk = get('fsk', FSK_LEVEL);

      delete data.stream_id;

      get.finish(Movie, this);
    }

    Object.assign(this, data);
  }

  isHd() {
    return min(this.width, this.height) >= 720;
  }

  /**
   * @returns {Promise<void>}
   * @param {boolean} [fmotd]
   */
  async goto(fmotd) {
    const nickname = await this.getAmateur().then(
      (amateur) => amateur.nickname,
      () => 'unknown'
    );

    location.hash = `/sedcard/${nickname}/movie/${this.id}${fmotd?'?fmotd':''}`;
  }

  /**
   * @deprecated
   * @param {"nickname" | "recommendation"} type
   * @param {number} limit
   */
  more(type, limit) {
    return inject('http')()
      .get(`/api/movie/more/${this.id}`, {params: {type, limit}, dontIntercept: true})
      .then(/** @param {any} res */ (res) => res.data);
  }
}

/*
const m = new Movie({
  id: 123,
  name: {18: {}, 16: {}, 12: {}},
  description: {18: {}, 16: {}, 12: {}},

  set_id: 321,
  user_id: 43,
  nickname: "huba",
  published: "werg4",
  fsk: 12,
  category_codes: ["bla", "test"],
  price: 5,
  votes: 10,
  rating: 3.3,
  filesize: 123,
  playtime: 234,
  height: 234,
  width: 543,
});
*/
