import {addClass} from '@acng/frontend-bounty';
import {Rendering} from '@acng/frontend-stargazer';
import angular, {IScope, ICompileService, IDirective} from 'angular';

import {rootRoute} from '../config/routes';
import {hasFeature} from '../service/env';
import {getLocale} from 'acng/locale/model/locale.js';
import {RootScope} from 'acng/zz-app';
import {hookFeature} from '../config/feature';

export type Scope = IScope & {
  hasFeature: typeof hasFeature;
  hook: JQLite;
  request: typeof rootRoute.globals;
  locale: () => string;
  hookname: string;
  hookData: unknown;
};


onswHookDirective.$inject = ['$rootScope', '$compile', '$sce'];
export function onswHookDirective(
  $rootScope: RootScope,
  $compile: ICompileService
): IDirective<Scope> {
  $rootScope.hasFeature = hasFeature;
  $rootScope.locale = getLocale;

  return {
    restrict: 'A',
    scope: {
      hookname: '@',
      hookData: '<?',
    },
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function ($scope, $element, $attrs) {
        const element = $element[0] as HTMLElement;
        const dashName = $attrs.hookname.toDash();

        addClass(element, `${dashName}-hook`, 'ons-layout');
        $scope.locale = getLocale;
        $scope.hasFeature = hasFeature;
        $scope.hook = $element;
        $scope.request = rootRoute.globals;

        // TODO deprecated
        if (angular.isDefined($attrs.extractData)) {
          $scope.$watch('hookData', (data: unknown) => {
            if (typeof data === 'object') {
              Object.assign($scope, data);
            }
          });
        }
        hookFeature.lookup($scope.hookname).then((template) => {
          const render = new Rendering(template);
          render.toElement(element);
          $compile(angular.element(element).children())($scope);
          $scope.$digest();
        }).catch(console.error);
      },
    ],
  };
}
