import {sessionOfAmateur} from '../service/games.js';

/**
 * @module acng/games/run/amateur
 *
 *    Bla huba
 *
 * @typedef {import('acng/amateurPool/factory/Amateur').AmateurConstructor} AmateurConstructor
 */
export const //
  type = 'run',
  param = run;

run.$inject = ['Amateur'];

/**
 * @param {AmateurConstructor} Amateur
 *
 *    The {@link AmateurConstructor} will be decorated.
 */
function run(Amateur) {
  /**
   * @returns {import('../model/game').Session | undefined}
   *
   *     Latest Game with this amateur
   */
  Amateur.prototype.getGame = function () {
    const session = sessionOfAmateur.get(this.id);
    if (session) {
      session.amateur = this;
    }
    return session;
  };
  /**
   * @returns {boolean}
   *
   *     `true` when the current user hat an active game with this amateur
   *     `false` otherwhise
   */
  Amateur.prototype.hasActiveGame = function () {
    return !!sessionOfAmateur.get(this.id)?.getState().isActive;
  };
}
