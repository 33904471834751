/**
 * @module acng/games/widgets/game
 * @typedef {import('acng/core/factory/widget').WidgetConstructor} WidgetConstructor
 * @typedef {import('acng/userPool/factory/user').User} User
 * @typedef {import('../model/game').Session} Session
 */
import templateUrl from '../../../html/games/onsw-game.html';
import imageSrcWon from '../../../basic/img/games/youWin.svg';
import imageSrcLost from '../../../basic/img/games/youLose.svg';
import imageSrcDraw from '../../../basic/img/games/gameOver.svg';
import userDummySrc from 'assets/basic/img/noImg.png';
export const name = 'onswGame';
export const type = 'component';
/** @type {angular.IComponentOptions} */
export const param = {
  bindings: {
    session: '<',
  },
  controller,
  controllerAs: 'game',
  templateUrl: `${media.assets}/${templateUrl}`,
};
import {asset, PLAYPAL_STATIC, media} from 'acng/core/service/env';
import {append, addClass, removeClass, setAttribute, clear, on, off, createSvg, createDiv, onClick, setStyle, remove} from '@acng/frontend-bounty';
import {ctxGameSession} from '../context/session.js';
import {getGame} from '../service/games.js';
import {listen} from 'acng/core/context/event-bus';
import {typeguard} from '@acng/frontend-bounty/typeguard.js';
import {EVENTBUS_GAME} from '../service/typeguard';
ctxGameSession.subscribeTagName('onsw-game');
controller.$inject = ['Widget', '$scope', '$element', 'user'];
/**
 * @param {WidgetConstructor} Widget - helper
 * @param {angular.IScope} $scope - required by {@link WidgetConstructor}
 * @param {JQuery} $element - required by {@link WidgetConstructor} + DOM manipulation
 * @param {User} user - show user nickname and profile image
 */
function controller(Widget, $scope, $element, user) {
  /** @type {HTMLDivElement | undefined} */
  let gameOver;
  const element = $element[0];
  /* prettier-ignore */
  new ResizeObserver(([{contentRect: {width}}]) => {
    element.classList.toggle('small', width < 500);
    element.classList.toggle('large', width >= 500);
  }).observe(element);
  /**
   * Used in template
   * @type {Session | undefined}
   */
  this.session;
  /**
   * @type {import('../model/game').State | undefined}
   */
  this.state;
  /**
   * Used in template
   * @type {User}
   */
  this.user = user;
  /**
   * async user profile image src
   * @type {string}
   * @todo use importet assed dummy
   */
  this.userImageUrl = asset(userDummySrc);
  user.getProfile().then(res => {
    if (res.data.images) {
      this.userImageUrl = `${media.user}${res.data.images.wide['480']}`;
    }
  });
  const widget = new Widget($scope, $element);
  /**
   * try to load a session
   * TODO route param types
   */
  const update = async () => {
    const session = this.session;
    DEBUG: console.debug('games/widget/game update', {session});
    const gameParent = $element[0].querySelector(':scope > .game');
    if (!gameParent) {
      throw new Error('game container element does not exist');
    }
    if (this.state) {
      // remove previous state classes
      for (const css in this.state) {
        removeClass(element, css);
      }
      remove(gameOver);
      gameOver = undefined;
    }
    clear(gameParent);
    if (!session) {
      this.state = undefined;
      return;
    }
    this.state = session.getState();
    for (const [css, enabled] of Object.entries(this.state)) {
      if (enabled) {
        addClass(element, css);
      }
    }
    DEBUG: console.debug('onsw-game $onChanges', {session, state: this.state});
    if (!this.state.hasSession) {
      return;
    }
    const gameElement = await widget.clear().busy(createElement(session));
    setStyle(gameElement, 'opacity', '0.3');
    append(gameParent, gameElement);
    on(gameElement, 'load', () => setStyle(gameElement, 'opacity', ''));
  };

  /**
   * @param {import('acng/core/context/event-bus').Data} data
   */
  const handleEvent = async (data) => {
    ASSERT: typeguard('', data, EVENTBUS_GAME());
    DEBUG: console.debug('games/widget/game handleEvent');
    if (this.session && this.session?.id == data.session?.id) {
      if (this.state?.hasSession) {
        DEBUG: console.debug('games/widget/game same running session - skip update', this.session);
        this.state = this.session.getState();
        for (const [css, enabled] of Object.entries(this.state)) {
          element.classList.toggle(css, enabled);
        }
        //$scope.$digest();
        if (this.state.isDecided) {
          /* prettier-ignore */
          const image = await createSvg(`${media.assets}/${
            this.state.isLost
              ? imageSrcLost
              : this.state.isWon
                ? imageSrcWon
                : imageSrcDraw}`
          );
          gameOver = createDiv('game-over');
          onClick(gameOver, () => gameOver?.remove());
          setStyle(gameOver, 'cursor', 'pointer');
          append(gameOver, image);
          append(element, gameOver);
        }
        return;
      }
      update();
    }
  };

  const unreg = listen('games.update', handleEvent);
  this.$onDestroy = () => unreg();
  this.$onChanges = update;
  /**
   * Create game client
   * @param {Session} session - session for the client
   * @returns {Promise<HTMLElement>} client ready for DOM
   * @private
   */
  const createElement = async session => {
    try {
      const game = session.gameId ? await getGame(session.gameId) : null;
      if (!game) {
        throw new Error(`game with id=${session.gameId} not found`);
      }
      const {default: Component} = await import(`${PLAYPAL_STATIC}${game.webComponentLoaderPath}`);
      const element = new Component();
      addClass(element, 'game');
      setAttribute(element, 'token', await session.postToken());
      return element;
    } catch (err) {
      addClass(element, 'error');
      widget.notify(err);
      throw err;
    }
  };
}
