import {FeatureUtility, createFeature} from '@acng/frontend-stargazer';
import {fetchHTML, fetchSVG} from '@acng/frontend-bounty/fetch';

import {INHERIT, asset, hasFeature, media} from '../service/env.js';
import {getLocale} from 'acng/locale/model/locale.js';
import {popup} from '@acng/frontend-discovery';
import {
  TAGNAME_TEMPLATE,
  addClass,
  append,
  createDiv,
  createElement,
  document,
  documentElement,
  isString,
  joinArray,
  loadImage,
  queryAll,
  setId,
} from '@acng/frontend-bounty';
import {createCacheMap} from '@acng/frontend-bounty/cache';

import {t} from 'acng/locale/config/translate.js';

const cache = createCacheMap();

createFeature('image', (path, insert) => {
  const src = `${media.assets_ext}/img/${joinArray(path, '/')}.js`;
  return cache(src, async () => {
    const image = await loadImage((await import(src)).default());
    addClass(image, 'image', joinArray(path, '-'));
    insert(image);
  });
});

createFeature('icon', ([featureName, imageName], insert) => {
  const src = require(`../../../${documentElement.dataset.portal}/img/${featureName}/${imageName}.svg`);
  return cache(src, async () =>
    insert(document.importNode((await fetchSVG(asset(src))).documentElement, true))
  );
});

export class Feature extends FeatureUtility {
  /**
   * @param {string} name
   */
  constructor(name, disabled = !hasFeature(name)) {
    const template = createFeature(name, async ([templateName], insert) => {
      const src = getTemplateSource(name, templateName);
      return cache(src, async () => {
        const doc = await fetchHTML(src);
        addLegacyStylesOnDemand(doc, templateName);
        await insert(doc);
      });
    });
    template.hidden = disabled;
    super(template);
  }

  /**
   * @param {string[] | string} src
   * @param {Record<string, string | string[]>} [params]
   * @todo TODO bounty add splitArray
   */
  async translate(src, params = {}) {
    if (isString(src)) {
      src = src.split('.');
    }
    return await t([this.name, ...src], params);
  }

  /**
   * @template [T = void]
   * @param {?Element} host
   * @param {string} name
   * @param {(close: ((value?: T) => void)) => Record<string, any>} params
   * @param {boolean} [modal]
   * @returns {Promise<T>}
   */
  async popup(host, name, params, modal) {
    const div = createDiv();
    const template = await this.render(name);

    template.toElement(
      div,
      params((value) => popup().close(div, value))
    );
    const res = popup(host ?? undefined)[modal ? 'showModal' : 'show'](div);

    // @ts-expect-error
    return await res;
  }
}

/**
 * @param {string} featureName
 * @param {string} templateName
 */
const getTemplateSource = (featureName, templateName) => {
  const variants = [
    ...INHERIT.map((v) => `-${getLocale()}-${v}`), //
    ...INHERIT.map((v) => `-${v}`),
    `-${getLocale()}`,
    '',
  ];
  /** @type {string | false} */
  let found = false;
  for (const variant of variants) {
    try {
      found = require(`../../../html/${featureName}/${templateName}${variant}.html`);
      if (found) {
        return asset(found);
      }
    } catch (reason) {
      continue;
    }
  }
  const msg = `template "${featureName}.${templateName}" not found ${variants.join(', ')}`;
  popup().error(msg);
  throw Error(msg);
};

/**
 * @deprecated
 * @param {Document} doc
 * @param {string} templateName
 */
const addLegacyStylesOnDemand = (doc, templateName) => {
  const templates = queryAll(TAGNAME_TEMPLATE, doc);

  switch (templates.length) {
    case 0:
      for (const child of doc.body.childNodes) {
        if (child instanceof Element && child.matches('.box')) {
          const dashName = toDash(templateName);
          addClass(child, `${dashName}-box`);
          for (const element of queryAll(':scope > *', child)) {
            addClass(element, `${dashName}-widget`, templateName);
          }
        }
      }
      const template = createElement(TAGNAME_TEMPLATE);
      setId(template, templateName);
      append(template.content, ...doc.body.childNodes);
      append(doc.head, template);
      break;
    case 1:
      setId(templates[0], templateName);
      break;
  }
};

/**
 * @deprecated
 * @param {string} str
 */
const toDash = (str) => str.replace(/([A-Z])/g, ($1) => `-${$1.toLowerCase()}`);
