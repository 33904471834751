import {createGlobalContext} from "@acng/frontend-relativity";
import {CTX_PROVIDE} from "@acng/frontend-relativity/minify";
import {typeguard} from "@acng/frontend-bounty/typeguard.js";

import {listen} from "acng/core/context/event-bus.js";
import {EVENTBUS_STOCK_ITEM} from "acng/payment/service/typeguard.js";

const MODULE = 'userPool/context/stock-update';
const VERBOSE = false;

/**
 * @type {import("@acng/frontend-relativity").Context<?import("acng/payment/service/typeguard").StockItem>}
 */
export const ctxStockUpdate = createGlobalContext(null);

listen('stock-item', (data) => {
  ASSERT: typeguard(`${MODULE} bus-data`, data, EVENTBUS_STOCK_ITEM());
  DEBUG: if (VERBOSE) console.info(MODULE, data.item);

  ctxStockUpdate[CTX_PROVIDE](null, data.item);
});
