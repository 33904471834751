import {listen} from 'acng/core/context/event-bus.js';
import {Session} from '../model/game.js';
import {typeguard, EVENTBUS_GAME, GAMES, INVITATIONS, SESSIONS} from '../service/typeguard.js';
import {inject} from 'acng/core/service/ng';
import {get as httpGet, cdn, post} from 'acng/core/service/backend.js';
import {dispatch} from '../context/session.js';
import {Amateur} from 'acng/amateurPool/model/amateur';

const MODULE = 'games/service/games';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {Promise<import('../service/typeguard.js').SessionData[]>}
 */
let futureSessions;
/**
 * @type {Promise<import('../service/typeguard.js').InvitationData[]>}
 * @private
 */
let invitations;
/**
 * @type {Map<number, Session>}
 * @private
 */
const all = new Map();
/**
 * @type {Map<string, Session>}
 * @private
 */
const amateurs = new Map();
/**
 * Get reference to session object from internal map. Created if not exits.
 * @param {number} id - playpal session/invitation id
 * @returns {Session} Existing or created game session object
 */
const get = (id) => {
  if (!all.has(id)) {
    all.set(id, new Session(id));
  }
  return /** @type {Session} */ (all.get(id));
};
/**
 * Register Invitation
 * @param {import('../service/typeguard.js').InvitationData} data - playpal invitation data
 * @returns {Session} Reference to managed session object
 * @private
 */
const registerInvitation = (data) => {
  /*const amateur = await Amateur.get(data.toId.split('-')[1]);
      if (!amateur) {
        throw new Error(`session #${session.id} amateur "${data.toId}" not found`);
      }*/
  const session = get(data.id);
  session.updateWithInvitation(data);
  if (!session.amateurId) {
    throw new Error(`session #${session.id} has no amateurId`);
  }
  const amateur = amateurs.get(session.amateurId);
  if (!amateur || (session.invitedAt ?? 0) > (amateur.invitedAt ?? 0)) {
    amateurs.set(session.amateurId, session);
  }
  return session;
};
/**
 * Register Session
 * @param {import('../service/typeguard.js').SessionData} data - playpal session data
 * @returns {Session} Reference to managed session object
 */
const registerSession = (data) => {
  const session = get(data.id);
  session.updateWithSession(data);
  return session;
};
export const sessionOfAmateur = amateurs;
export const sessions = all;

/**
 * @param {number} id - playpal game id
 * @returns {Promise<?import("../service/typeguard").Game>} game object or null
 */
export const getGame = async function (id) {
  const res = await cdn('games');
  ASSERT: typeguard(MODULE, res, GAMES());
  return res.find((game) => game.id == id) ?? null;
};

/**
 * @param {string} amateurId - id of amateur to get the running game from
 * @returns {Promise<Session|undefined>} running session or undefined
 */
export const getRunningGame = async function (amateurId) {
  await getSessions();
  const session = amateurs.get(amateurId);
  if (session?.getState().isActive) {
    return session;
  }
};

/**
 * @param {Amateur} amateur - amateur to get the latest game from
 * @returns {Promise<Session|undefined>} latest session or undefined
 */
export const getLatestGame = async function (amateur) {
  await getSessions();
  return amateurs.get(amateur.id);
};

/**
 * @param {Amateur} amateur - amateur to invite
 * @returns {Promise<Session>} created session
 * @todo game-parameter
 */
export const invite = async function (amateur) {
  DEBUG: console.info(MODULE, 'invite', {amateur});
  if (inject('user').guestSignup()) {
    throw null;
  }
  if (!amateur.isOnline()) {
    throw new Error(`amateur #${amateur.id} is not online`);
  }
  const invitation = await post(`games/invitation/${amateur.id}`, {});
  const session = registerInvitation(invitation);
  dispatch(session);
  //this.dispatchEvent(new CustomEvent('update', {detail: {session}}));
  DEBUG: console.info(MODULE, 'invited', {amateur, session});
  return session;
};

/**
 * @returns {Promise<import('../service/typeguard.js').InvitationData[]>} any
 */
export const getInvitations = async function () {
  if (inject('user').guest) {
    return [];
  }
  if (!invitations) {
    invitations = httpGet('games/invitations').then((res) => {
      ASSERT: typeguard(`${MODULE} invitations`, res, INVITATIONS());
      DEBUG: if (VERBOSE) console.groupCollapsed(MODULE, 'getInvitations');
      for (const data of res) {
        registerInvitation(data);
      }
      DEBUG: if (VERBOSE) console.groupEnd();
      return res.filter((invitation) => invitation.status != 'accepted');
    });
  }
  return invitations;
};

/**
 * @returns {Promise<import('../service/typeguard.js').SessionData[]>} All running and decided games of the currently logged in user
 */
export const getSessions = function () {
  if (inject('user').guest) {
    return Promise.resolve([]);
  }
  if (!futureSessions) {
    futureSessions = Promise.all([
      getInvitations(),
      httpGet('games/sessions').then((res) => {
        ASSERT: typeguard(`${MODULE} sessions`, res, SESSIONS());
        DEBUG: if (VERBOSE) console.groupCollapsed(MODULE, 'getSessions');
        for (const data of res) {
          registerSession(data);
        }
        DEBUG: if (VERBOSE) console.groupEnd();
        dispatch(null);
        return res;
      }),
    ]).then((all) => all[1]); //.then(all => all[2].concat(all[1]));
  }
  return futureSessions;
};

/* register socket event handler */
listen('games.update', async (data) => {
  ASSERT: {
    typeguard(MODULE, data, EVENTBUS_GAME());

    if (!data.invitation) {
      console.error(MODULE, 'games.update without invitation', {data});
      return;
    }
  }

  DEBUG: if (VERBOSE) console.info(MODULE, 'event incoming', {data});

  const session = registerInvitation(data.invitation);

  if (data.session) {
    ASSERT: if (data.session.id != session.id) {
      console.error(MODULE, 'games.update wrong session', {data});
      return;
    }

    registerSession(data.session);
  }

  dispatch(session);
});
