/*angular.module('special')

  .factory('Christmas', ['user', 'onsOverlay', 'Amateur', '$log', '$q', (user, onsOverlay, Amateur, $log, $q) => special => {
    if (user.guestSignup()) {
      return $q.reject('guest');
    }
    return Amateur.getByNickname(special.nickname).catch(err => {
      $log.warn('CHRISTMAS: Amateur not found', { special, err });
      return null;
    }).then(amateur => {
      onsOverlay.create('christmas', { amateur, css: special.css_class })
        .disableBackdrop()
        .open();
    });
  }])*/

import {media} from 'acng/core/service/env';

import {AmateurConstructor, Amateur} from 'acng/amateurPool/factory/Amateur';
import {WidgetConstructor} from 'acng/core/factory/widget';
import {Payment} from 'acng/payment/factory/payment';

type DayData = {
  xmas_day: number;
  coins: number;
  created_at: string;
  cashed_at: string;
  amateur?: Amateur;
  content: {
    userId: number;
    nickname: string;
    type: 'jpg' | 'mp4';
  };
};
type GetData = {
  doors: DayData[];
  date: number;
};
type PutData = GetData & {
  notify: string;
  msg: string;
  content: string;
};
type Scope = angular.IScope & {
  days: DayData[];
  css: (day: DayData) => string[];
  style: (day: DayData) => Record<string, string> | undefined;
  click: (day: DayData) => void;
};

export default 'onswChristmas';
export const christmasComponent = {
  scope: {
    overlay: '<', // TODO context
  },
  template:
    '<div class="box"><div ng-click="click(day)" ng-class="css(day)" ng-style="style(day)" ng-repeat="day in days"><div class="number">{{ day.xmas_day }}</div><div class="coins" ng-show="day.coins">{{ day.coins | number:0 }} Coins</div><div class="nickname" ng-if="day.amateur" ng-click="overlay.close();day.amateur.goto();$event.stopPropagation()">{{ day.amateur.getNickname() }}</div></div></div>',
  controller: ['$scope', '$element', 'Widget', '$http', 'payment', 'Amateur', controller],
};

function controller(
  scope: Scope, //
  element: JQLite,
  Widget: WidgetConstructor,
  $http: angular.IHttpService,
  payment: Payment,
  Amateur: AmateurConstructor
) {
  let date = 1;

  function getDoorCssClasses(day: DayData) {
    const classes = ['day', 'day' + day.xmas_day];
    if (day.xmas_day == date) {
      classes.push('active');
    }
    if (day.created_at) {
      classes.push('created');
    }
    if (day.cashed_at) {
      classes.push('cashed');
    }
    if (day.content) {
      classes.push('content');
    }
    if (day.coins > 180) {
      classes.push('coins-xxl');
    } else if (day.coins > 60) {
      classes.push('coins-xl');
    } else if (day.coins > 20) {
      classes.push('coins-l');
    } else if (day.coins > 10) {
      classes.push('coins-m');
    } else {
      classes.push('coins-s');
    }
    return classes;
  }

  const widget = new Widget(scope, element);

  function loadContent() {
    scope.days.forEach(day => {
      if (day.cashed_at && day.content) {
        Amateur.get(day.content.userId).then(amateur => {
          day.amateur = amateur;
          console.info('xmas', day, amateur);
        });
      }
    });
  }

  widget
    .busy(
      $http.get<GetData>('/api/user/xmas').then(res => {
        scope.days = res.data.doors;
        date = res.data.date;
        loadContent();
      }) as Promise<any>
    )
    .catch(err => widget.notify(err));

  scope.css = getDoorCssClasses;
  scope.style = day => {
    if (day.cashed_at && day.content) {
      return {
        'background-image': 'url("' + media.content.movies + '/mediafiles/fhg/xmas2024/' + day.content.nickname + '.jpg")',
      };
    }
  };
  let closeMsg = () => {};

  scope.click = day => {
    widget
      .ifNotBusy(() => {
        closeMsg();
        element.removeClass('day-cashed day-created day-empty day-other');

        return $http.put(`/api/user/xmas/${day.xmas_day}`, {});
      })
      .then((res: angular.IHttpResponse<PutData>) => {
        scope.days = res.data.doors;
        date = res.data.date;
        loadContent();

        if (res.data.notify) {
          if (res.data.notify == 'payment') {
            payment.overlay('userPool.xmas.payment');
            return;
          }
          if (res.data.notify == 'content') {
            window.open(`${media.content.movies}/mediafiles/fhg/xmas2024/${day.content.nickname}.${res.data.content}.${day.content.type}`);
            return;
          }
          if (!date) return;
          element.addClass('day-' + res.data.msg);
          closeMsg = widget.notify(`userPool.xmas.${res.data.notify}`, {
            date: res.data.date,
            coins: res.data.doors.find(v => v.xmas_day == day.xmas_day)!.coins,
          });
        }
      })
      .catch(err => {
        if (err != 'busy') {
          widget.notify(err);
        }
      });
  };
}
