/**
 * @module amateurPool/widgets/amateur-list-small
 *
 * What it does:
 * - Show an icon according to the amateur.
 * What it does NOT:
 * - Show loading animation.
 */
import {createElement, getAttribute, toggleClass} from '@acng/frontend-bounty';
import {createPerceptiveHTML} from '@acng/frontend-discovery';
import {createStream} from '@acng/frontend-relativity';
import {CTX_OBSERVE, CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng';
import {ctxOnline} from '../service/online.js';
import {getAmateurVisits} from 'acng/amateurPool/service/profile-visit.js';
import {Rendering, defineRegistryElement, defineScope, stargazer} from '@acng/frontend-stargazer';
import {
  connectedCallback,
  defineElement,
  disconnectedCallback,
  HTMLElement,
} from '@acng/frontend-bounty/dom/custom.js';
import {TAGNAME_TEMPLATE} from '@acng/frontend-bounty/dom/type.js';
import {ctxAmateur} from '../context/amateur.js';
import {query} from '@acng/frontend-bounty/dom/query.js';

const MODULE = 'amateurPool/widget/list';
const VERBOSE = false;

defineRegistryElement('onsw-amateur-list', (name) => {
  const STYLE_MESSENGER_ONLINE = 'messenger-online';
  const STYLE_LIVECAM_ONLINE = 'livecam-online';
  const STYLE_LIVECAM_TOYCONTROL = 'livecam-toycontrol';

  const Amateur = inject('Amateur');

  defineScope(name, ['nickname']);

  ctxOnline.subscribeTagName(`${name} > article:not(:empty)`);

  defineElement(
    name,
    class extends HTMLElement {
      #renderItem = (() => {
        const fromAttribute = getAttribute(this, 'item-template');

        if (fromAttribute) {
          return stargazer.render(...fromAttribute.split('.'));
        }

        const childTemplate = query(this, TAGNAME_TEMPLATE);

        if (!childTemplate) {
          throw Error();
        }

        return new Rendering(childTemplate);
      })();

      #stream = createStream(/** @type {string[]} */ ([]));

      #warpdrive = createPerceptiveHTML(this, this.#stream, async (amateurId, loader) => {
        const rendering = await this.#renderItem;
        const amateur = await Amateur.get(amateurId);
        const image = await amateur.getImage('800');

        if (!loader.target) {
          return;
        }

        const element = loader.target;
        ctxAmateur[CTX_PROVIDE](element, amateur);
        const fragment = rendering.toElement(element, {
          nickname: amateur.getNickname(),
          image: image.src,
          gotoSedcard: () => amateur.goto(),
        });

        // ctxLivecams[CTX_OBSERVE](element), () => {
        toggleClass(element, STYLE_LIVECAM_ONLINE, amateur.livecamIsOnline());
        toggleClass(element, STYLE_LIVECAM_TOYCONTROL, amateur.hasToycontrol());
        // });

        ctxOnline[CTX_OBSERVE](loader.target, async (isOnline) => {
          toggleClass(element, STYLE_MESSENGER_ONLINE, isOnline(amateur.id));
        });
      });

      [connectedCallback]() {
        const host = this;
        const stream = this.#stream;
        const perceptive = this.#warpdrive;

        DEBUG: if (VERBOSE) this.#warpdrive.enableDebug(`${host.localName}:${host.dataset.name ?? 'ERROR'}`);
        if (this.dataset.name == 'visits') {
          getAmateurVisits().then((visits) =>
            visits.observe(host, (ids) => {
              stream.streamReset(ids.slice());
              perceptive.connect();
            })
          );
        } else {
          inject('AmateurFilter')
            .get(host.dataset.name ?? '')
            .list(20000)
            .then((res) => {
              stream.streamReset(res.slice());
              perceptive.connect();
            });
        }
      }

      [disconnectedCallback]() {
        this.#warpdrive.disconnect();
      }
    }
  );
});
