import {
  removeChildNodes,
  addClass,
  toggleClass,
  createSpan,
  append,
  removeClass,
  onClick,
  off,
  prepend,
  setText,
  remove,
} from '@acng/frontend-bounty';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getIcon} from 'acng/core/service/icon';
import {
  LAYOUT_CLASS_ACTIVE,
  LAYOUT_CLASS_DISABLED,
  LAYOUT_CLASS_INACTIVE,
  LAYOUT_CLASS_WAIT,
} from 'acng/layout/config/css-classes';

import {checkPin, ctxPins} from '../service.js';
import {httpDelete, put} from 'acng/core/service/backend.js';
import {pinFeature} from '../config/feature.js';
import {HTMLElement, connectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {t} from 'acng/locale/config/translate.js';

const MODULE = 'pin/widget/button';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

pinFeature.defineElement(
  'onsw-pin-button',
  class extends HTMLElement {
    [connectedCallback]() {
      controller(this);
    }
  },
  [ctxAmateur, ctxPins]
);

/**
 * @param {HTMLElement} element
 */
function controller(element) {
  addClass(element, 'ons-item');

  ctxAmateur[CTX_OBSERVE](element, async (amateur) => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, {element, amateur});

    removeChildNodes(element);
    removeClass(element, LAYOUT_CLASS_ACTIVE, LAYOUT_CLASS_INACTIVE);
    if (!amateur) {
      return;
    }
    /**
     * @type {SVGSVGElement | undefined}
     */
    let activeIcon;
    const box = createSpan('box', 'pin-button');
    const label = createSpan('label');
    const enable = () => {
      onClick(box, click);
      removeClass(box, LAYOUT_CLASS_WAIT, LAYOUT_CLASS_DISABLED);
    };
    const click = async () => {
      off(box, 'click', click);
      addClass(box, LAYOUT_CLASS_WAIT);

      try {
        if (!checkPin(amateur)) {
          await put(`pin/amateur/${amateur.id}`, {}, element);
          removeClass(box, LAYOUT_CLASS_WAIT);
          addClass(box, LAYOUT_CLASS_DISABLED);
        } else {
          httpDelete(`pin/amateur/${amateur.id}`, element);
          removeClass(box, LAYOUT_CLASS_WAIT);
          addClass(box, LAYOUT_CLASS_DISABLED);
        }
        /*
      } catch (reason) {
        if (reason.status == 401) {
          user.guestSignup('pin.signupRequired', {nickname: amateur.getNickname()});
        } else {
          console.error(reason);
          // TODO widget.notify(err); behaviour
          if (reason?.data?.message) {
            removeClass(box, LAYOUT_CLASS_WAIT);
            addClass(box, LAYOUT_CLASS_DISABLED);
            await popup(box).error(reason.data.message);
          }
          throw reason;
        }
      */
      } finally {
        enable();
      }
    };
    ctxPins[CTX_OBSERVE](element, async (pinsAreAvailable) => {
      DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, {element, pinsAreAvailable});
      if (!pinsAreAvailable) {
        return;
      }
      const isPin = checkPin(amateur);
      toggleClass(box, LAYOUT_CLASS_ACTIVE, isPin);
      toggleClass(box, LAYOUT_CLASS_INACTIVE, !isPin);
      getIcon(isPin ? 'favoriteColor' : 'noFavorite').then((svg) => {
        if (activeIcon) {
          remove(activeIcon);
        }
        activeIcon = svg;
        prepend(box, svg);
      });
    });
    append(box, label);
    enable();
    append(element, box);
    setText(label, await t('pin.pin'));
  });
}
