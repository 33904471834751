/**
 * @module acng/special
 */
import angular from 'angular';
import {hasFeature} from 'acng/core/service/env';
import {provideSpecial} from './service/special';
import {TeaserTile} from './tiles/TeaserTile';
import {provideTranslations} from 'acng/core/service/angular-translate.js';
import onswChristmas, {christmasComponent} from './widget/christmas';

const featureName = 'special';
export default featureName;

angular
  .module(featureName, ['core', 'userPool', 'pascalprecht.translate']) // (achievement)
  .config(provideTranslations(featureName))
  .run([
    '$rootScope',
    /**
     * @param {import('acng/zz-app').RootScope} $rootScope
     */
    $rootScope => {
      $rootScope.$on('userPool.login.success', () => {
        if (hasFeature(featureName)) {
          provideSpecial();
        }
      });
    },
  ])
  .component(onswChristmas, christmasComponent)
  .factory('TeaserTile', () => TeaserTile);
