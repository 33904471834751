/**
 * @module User´s Friendships
 * @since 3.78.0
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * Provides the friendships and updates to the friendships of the `AuthUser`.
 *
 * @example
 * ```js
 * // Get the friendship state for the `amateur`
 * const friendship = getFriendship(amateur.id);
 *
 * // Observe friendship changes
 * ctxFriendship.observe(element, (friendship) => {
 *   console.log('friendship changed', friendship);
 * });
 *
 * // Observe the list of confirmed friends
 * ctxFriends.observe(element, (friends) => {
 *   console.log('confirmed friends changed', friends);
 * });
 *
 * ```
 */

import {createElement, createGlobalContext} from '@acng/frontend-relativity';
import {clearArray, forEachOfArray} from '@acng/frontend-bounty';
import {clear, forEach} from '@acng/frontend-bounty/object.js';
import {CTX_OBSERVE, CTX_PROVIDE, CTX_VALUE} from '@acng/frontend-relativity/minify';

import {listen} from 'acng/core/context/event-bus.js';
import {EVENTBUS_FRIENDSHIP, USER_FRIENDS_DATA, typeguard} from '../service/typeguard.js';
import {ErrorResponse, get} from 'acng/core/service/backend.js';
import {POSSIBLE_STATES, STATE_CONFIRMED, STATE_RESET} from '../service/friendship.js';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {hasFeature} from 'acng/core/service/env.js';

const MODULE = 'friend/context/friends';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn(`Import verbose ${MODULE}`);

/**
 * @typedef {typeof POSSIBLE_STATES[number]} FriendshipState
 */

/**
 * @type {?ErrorResponse}
 */
export let error = null;

/**
 * @internal
 * @type {Readonly<Record<FriendshipState, string[]>>}
 */
export const friendships = POSSIBLE_STATES.reduce(
  (res, cur) => ((res[cur] = []), res),
  /** @type {Record<FriendshipState, string[]>} */ ({})
);

/**
 * @param {string} amateurId
 */
export const getFriendship = (amateurId) => statusOfAmateur[amateurId] ?? STATE_RESET;

/**
 * @type {Record<string, FriendshipState | undefined>}
 */
const statusOfAmateur = {};

/**
 * Provides `friendship` updates or `null` when many friendships changed.
 *
 * @type {import("@acng/frontend-relativity").Context<?UserFriendData>}
 */
export const ctxFriendship = createGlobalContext(null);

/**
 * Provides an array of `amateurId` with confirmed friendship state.
 */
export const ctxFriends = createGlobalContext(friendships[STATE_CONFIRMED]);

(() => {
  if (!hasFeature('friend')) {
    return;
  }

  /**
   * Put a friendship update into the {@link friendships} and {@link statusOfAmateur}
   * @param {UserFriendData} friend
   */
  const put = (friend) => {
    DEBUG: if (VERBOSE) console.debug(MODULE, 'put', {friend});

    const {partner: {id: amateurId}, status} = friend;
    statusOfAmateur[amateurId] = status;

    forEachOfArray(POSSIBLE_STATES, (s) => {
      const list = friendships[s];
      const i = list.indexOf(amateurId);

      if (s == status) {
        if (i < 0) {
          list.unshift(amateurId);
        }
      } else if (i >= 0) {
        list.splice(i, 1);
      }
    });
  };

  /**
   * Notify observers about changes in friendships.
   * @param {?UserFriendData} friendship
   */
  const provide = (friendship = null) => {
    DEBUG: if (VERBOSE) console.info(MODULE, 'provide', {friendship, friendships});

    ctxFriendship[CTX_PROVIDE](null, friendship);
    ctxFriends[CTX_PROVIDE](null, friendships[STATE_CONFIRMED]);
  };

  const element = createElement();

  // Observe the `AuthUser` and update the friendships accordingly.
  ctxEventBusUser[CTX_OBSERVE](element, async (currentUser, previous) => {
    DEBUG: if (VERBOSE) console.info(MODULE, {currentUser});

    if (currentUser?.id != previous?.id) {
      forEach(friendships, clearArray);
      clear(statusOfAmateur);
      error = null;
      provide();
    }

    if (currentUser) {
      const friendsData = await get(`friends`, element, async (err) => {
        error = err;
        return /** @type {unknown} */ ([]);
      });

      ASSERT: typeguard(`${MODULE} fetched`, friendsData, USER_FRIENDS_DATA());
      DEBUG: if (VERBOSE) console.info(MODULE, {friendsData, currentUser});

      if (currentUser.id == ctxEventBusUser[CTX_VALUE](element)?.id) {
        forEachOfArray(friendsData, put);

        provide();
      }
    }
  });

  listen('friendship', (data) => {
    ASSERT: typeguard(MODULE, data, EVENTBUS_FRIENDSHIP());
    DEBUG: if (VERBOSE) console.info(MODULE, 'listen', {data});

    put(data);
    provide(data);
  });
})();

/**
 * @typedef UserFriendData
 * @property {{pool_id: number, id: string}} partner
 * @property {FriendshipState} status
 * @property {number} timestamp_ms
 */
