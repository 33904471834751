import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {removeChildNodes, removeClass, addClass, removeNode, setText} from '@acng/frontend-bounty';
import {defineCompileElement, defineRegistryElement} from '@acng/frontend-stargazer';
import {defineElement, HTMLElement, connectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {inject} from 'acng/core/service/ng.js';
import {LAYOUT_CLASS_ACTIVE, LAYOUT_CLASS_INACTIVE} from 'acng/layout/config/css-classes.js';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {hasFeature} from 'acng/core/service/env.js';

const MODULE = 'livecam/widgets/motto';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRegistryElement('onsw-livecam-motto', (localName) => {
  DEBUG: if (VERBOSE) console.info(MODULE, 'define', localName);

  /**
   * @type {import('acng/livecam/factory/livecam').LivecamConstructor}
   */
  let Livecam;

  ctxAmateur[CTX_SUBSCRIBE_TAGNAME](localName);

  defineCompileElement(localName, (element) => {
    if (!hasFeature('livecam')) {
      DEBUG: if (VERBOSE) console.debug(MODULE, 'feature is hidden');

      removeNode(element);
    } else {
      Livecam = inject('Livecam');
    }
  });

  defineElement(localName, class extends HTMLElement {
    [connectedCallback]() {
      const element = this;

      ctxAmateur[CTX_OBSERVE](element, (amateur) => {
        removeChildNodes(element);
        removeClass(element, LAYOUT_CLASS_ACTIVE);
        addClass(element, LAYOUT_CLASS_INACTIVE);

        if (!amateur) {
          return;
        }

        const livecam = Livecam.get(amateur.id);
        if (!livecam) {
          return;
        }

        const text = fskTrans(livecam.motto);
        DEBUG: if (VERBOSE) console.debug(MODULE, {amateur, livecam, text});
        if (!text) {
          return;
        }

        removeClass(element, LAYOUT_CLASS_INACTIVE);
        addClass(element, LAYOUT_CLASS_ACTIVE);
        setText(element, text);
      });
    }
  });
});
