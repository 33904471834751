/** @module acng/games/widgets/link */
export const name = 'onswGamesLink';
export const type = 'component';
import {addClass, removeClass, on, off, toggleClass} from '@acng/frontend-bounty';
import {ctxEventBusUser} from 'acng/userPool/context/event-bus-user.js';
import {ctxGameSession} from '../context/session.js';
import {Session} from '../model/game.js';
import {getSessions, sessionOfAmateur} from '../service/games.js';

const MODULE = 'games/widgets/link';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

ctxEventBusUser.subscribeTagName('onsw-games-link');
ctxGameSession.subscribeTagName('onsw-games-link');

/** @type {angular.IComponentOptions} */
export const param = {
  template: '<a class="box" href="#/games"><span class="label">{{ \'games.games\' | translate }}</span><span class="value">{{ $ctrl.count }}</span></a>',
  /** @implements {angular.IController} */
  controller: class {
    static $inject = ['$scope', '$element', 'Widget', 'user', '$compile'];
    /**
     * @param {angular.IScope} $scope - listen to user login event
     * @param {JQuery} $element - required by {@link core.WidgetConstructor} and animation stuff
     * @param {angular.ICompileService} $compile - compile animation element
     */
    constructor($scope, $element, $compile) {
      /**/
      let animationCount = 0;
      /**/
      let animationRunning = false;
      /**/
      const element = $element[0];
      addClass(element, 'ons-item');
      on(element, 'transitionend', () => removeClass(element, 'wumper'));
      /**/
      this.count = 0;
      /**/
      this.$onInit = () => {
        DEBUG: console.debug('games/widgets/link $onInit');
        ctxEventBusUser.observe(element, async (authUser) => {
          if (!authUser) {
            $scope.$applyAsync(() => this.count = 0);
            return;
          }

          await getSessions();
          ctxGameSession.observe(element, update);
        });
      };
      /**
       * @param {?Session} session
       */
      const update = (session) => {
        DEBUG: if (VERBOSE) console.debug(MODULE, 'update', {session});
        this.count = 0;
        for (const session of sessionOfAmateur.values()) {
          if (session.getState().isActive) {
            this.count++;
          }
        }
        toggleClass(element, 'empty', !this.count);
        $scope.$apply();
        const state = session?.getState();
        if (state && state.isActive && !state.userTurn && !state.amateurTurn) {
          increaseWithAnimation();
        } else if (this.count) {
          addClass(element, 'wumper');
        }
      };
      /**/
      const increaseWithAnimation = () => {
        animationCount++;
        if (animationRunning) {
          return;
        }
        animationRunning = true;
        $compile('<span class="gain" ons-icon="::*,gameColor"></span>')($scope)
          .appendTo($element)
          .on('animationend', ev => {
            animationRunning = false;
            ev.stopPropagation();
            //$scope.$apply(() => (this.count = this.count + animationCount));
            DEBUG: console.debug('games/widgets/link animationend', this.count);
            if (ev.target instanceof HTMLElement) {
              ev.target.remove();
            }
            addClass(element, 'wumper');
          });
      };
    }
  },
};
