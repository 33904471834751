import {middleware, path} from '@acng/frontend-voyager';

import {mainView} from 'acng/layout/service/view-middleware.js';
import {userRoute} from 'acng/userPool/config/routes';

import {pinFeature} from './feature.js';
import {t} from 'acng/locale/config/translate.js';

if (!pinFeature.isHidden) {
  userRoute.create(
    path('/pins'),
    middleware(async (next) => {
      next.noFriendsText = await t('pin.noPins');
    }),
    mainView(pinFeature, 'pins')
  );
}
