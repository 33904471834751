/**
 * @module Open cookiebar
 * @since 3.83.0
 * @author Markus Kirscht <markus.kirscht@iventuregroup.com>
 * @author Jacob Viertel <jv@onscreen.net>
 *
 * On click opens cookiebar from cookie script to adjust cookie consent settings
 *
 * ```html
 * <a href="javascript:void(0)" class="layout-link preset-button" :open-cookiebar>
 *    <span class="label">#{ translate:userPool.cookies.button }</span>
 * </a>
 * ```
 */
import {onClick} from '@acng/frontend-bounty';
import {defineRegistryAttribute, defineRenderAttribute} from '@acng/frontend-stargazer';

const MODULE = 'userPool/config/controller/open-cookiebar';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Verbose import', MODULE);

defineRegistryAttribute(':open-cookiebar', (name) =>
  defineRenderAttribute(name, (element) => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'render', {element});
    const cookieScript = window.CookieScript;
    if (cookieScript && cookieScript.instance) {
      DEBUG: if (VERBOSE || element.hasAttribute('debug'))
        console.info(MODULE, 'CookieScript instance found, bind click event');
      onClick(element, cookieScript.instance.show);
    } else {
      console.error(name, element);
    }
  })
);
