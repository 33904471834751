/**
 * @module acng/regard/factory/regard
 */
import {media} from 'acng/core/service/env';
import {inject} from 'acng/core/service/ng';
import {recentMessageIsIcebreaker} from 'acng/messenger/service/recent-message.js';
import {publishUserMessage} from 'acng/messenger/service/event-bus.js';
import {Message} from 'acng/messenger/model/message.js';
import {popup} from '@acng/frontend-discovery';

type Amateur = import('acng/amateurPool/factory/Amateur').Amateur;
type FskTrans = import('acng/userPool/0fsk').FskTrans;

export type RegardData = {
  id: number;
  price: number;
  icon: string;
  name: FskTrans;
  description: FskTrans;
};

type RegardSendData = {
  recipient: string;
  regard: number;
  message: string;
  icebreakerAnswer: boolean;
  livecamTip?: boolean;
};

export class Regard {
  static recipient: Amateur | null = null;

  id: number;
  price: number;
  icon: string;
  name: FskTrans;
  description: FskTrans;
  livecamTip = false;

  constructor(data: RegardData) {
    this.name = data.name;
    this.icon = data.icon;
    this.id = data.id;
    this.description = data.description;
    this.price = data.price;
  }

  getIconUrl() {
    return `${media.content.items}${this.icon}`;
  }

  async send(text: string) {
    if (!Regard.recipient) {
      throw Error('something went wrong');
    }
    const http = inject('http');
    const user = inject('user');

    try {
      // TODO
      await http().post(
        'api/regard/send',
        {
          recipient: Regard.recipient.id,
          regard: this.id,
          message: text,
          livecamTip: this.livecamTip,
          icebreakerAnswer: await recentMessageIsIcebreaker(Regard.recipient),
        } as RegardSendData,
        {
          headers: {'Content-Type': 'application/json'},
          dontIntercept: true,
        }
      );
      publishUserMessage(new Message(Regard.recipient, {
        sender: {
          product_id: user.pool_id,
          id: `${user.id}`,
        },
        body: text,
        timestamp_ms: Date.now(),
        attachment: null,
        payload: {
          type: 'regard',
          image: this.icon,
          text: text,
        },
      }));
    } catch (err: any) {
      const payment = inject('payment');
      switch (err.status) {
        case 401:
          user.guestSignup('regard.signupRequired', {nickname: Regard.recipient.getNickname()}) || user.clear();
          throw null;
        case 402:
          if (err.data.code === 4001) {
            throw err;
          }
          payment.overlay('regard.paymentRequired', {nickname: Regard.recipient.getNickname()});
          throw null;
        case 409:
          popup().error(err.data.message);
          throw null;
      }
      throw err;
    }
  }

  async sendTo(amateur: Amateur, overlay?: import('acng/core/service/overlay').Overlay) {
    const fskTransFilter = inject('fskTransFilter');
    Regard.recipient = amateur;
    const res = await this.send(fskTransFilter(this.description));
    overlay?.close();
    return res;
  }
}
