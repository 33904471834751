import angular from 'angular';

import {startUpLanguage, VERSION} from './service/env';
import {configDebug} from './config/debug';
import './config/routes';
import './tiles/ImageTile';
import './widgets/tiles';
import './factory/widget';
import './widgets/timer';
import './widgets/slideshow';
import overlayName, {overlayFactory} from './service/overlay';
import {onswTileDirective} from './widgets/tile';
import {onswHookDirective} from './widgets/hook';
import './widgets/template.js';
import onswTiles, {tilesComponent} from './widgets/tiles';
import zipFilterName, {zipFilter} from './filter/zip';
import onsIcon, {iconDirective} from './directive/icon';
import {onsApiValidatorDirective} from './directive/apiValidator.js';
import {ImageTile} from './tiles/ImageTile.js';
import './widgets/contentRemoval.js';
import './widgets/securityCode.js';
import './widgets/icon.js';
import './widgets/smileys.js';
import './filter/smileys.js';
import featureName from './config/feature.js';
import {provideTranslations} from './service/angular-translate';

export default featureName;

angular
  .module(featureName)
  .config(configDebug)
  .config([
    '$translateProvider',
    /**
     * @param {angular.translate.ITranslateProvider} $translateProvider
     */
    ($translateProvider) => {
      $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/language/{lang}/{part}?v=' + VERSION,
      });
      $translateProvider.cloakClassName('ng-hide');
      $translateProvider.preferredLanguage(startUpLanguage);
      $translateProvider.useSanitizeValueStrategy('escaped');
    },
  ])
  .config(provideTranslations(featureName))
  .config(provideTranslations('categories'))
  .config(provideTranslations('exception'))
  .config(provideTranslations('actionslider'))
  .config(provideTranslations('validation'))
  .config(provideTranslations('countries'))
  .config(provideTranslations('myportal'))
  .factory(overlayName, overlayFactory)
  .factory('ImageTile', () => ImageTile)
  .filter(zipFilterName, zipFilter)
  .directive('onsApiValidator', [() => onsApiValidatorDirective])
  .directive('onswTile', onswTileDirective)
  .directive('onswHook', onswHookDirective)
  .directive(onsIcon, iconDirective)
  .component(onswTiles, tilesComponent);
