import {Rendering, defineCustomWidget} from '@acng/frontend-stargazer';
import {createPerceptiveHTML} from '@acng/frontend-discovery';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {pinFeature} from '../config/feature.js';
import {byAmateur, ctxPins} from '../service.js';
import {HTMLElement, append, createDiv, createTemplate} from '@acng/frontend-bounty';
import {AmateurTile} from 'acng/amateurPool/tiles/AmateurTile.js';
import {createStream} from '@acng/frontend-relativity';
import {connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';
import {getElementById} from '@acng/frontend-bounty/dom/query.js';
import {IS, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {size} from '@acng/frontend-bounty/collection.js';

const MODULE = 'pin/widget/list';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {WeakMap<object, import('angular').IScope>}
 */
const scopes = new WeakMap();

defineCustomWidget(
  pinFeature,
  'onsw-pin-list',
  class extends HTMLElement {
    #transcluded = createTemplate();

    #stream = createStream([], async (offset) => {
      if (offset) {
        return [];
      }

      if (!size(byAmateur) && this.#renderEmpty) {
        append(this, this.#renderEmpty.toElement(createDiv()));
        return [];
      }

      DEBUG: if (VERBOSE) console.info(MODULE, byAmateur);

      return [...byAmateur];
    });

    #warpdrive = createPerceptiveHTML(
      this,
      this.#stream,
      async (data, loader) => {
        DEBUG: if (VERBOSE) console.debug(MODULE, 'render', {data});
        ASSERT: typeguard(MODULE, this.#itemTemplate, IS(Rendering));
        const tile = new AmateurTile(data);
        await tile.render(this.#itemTemplate, loader);
        scopes.set(loader, tile.scope);
        DEBUG: if (VERBOSE) console.debug(MODULE, 'rendered', {tile});
      },
      (loader) => scopes.get(loader)?.$destroy()
    );

    /**
     * @type {?RenderingEmpty}
     */
    #renderEmpty = null;

    /**
     * @type {?Rendering}
     */
    #itemTemplate = null;

    [connectedCallback]() {
      const fragment = this.#transcluded.content;
      append(fragment, ...this.childNodes);

      const empty = getElementById(fragment, 'empty');
      const item = getElementById(fragment, 'item');

      ASSERT: typeguard(MODULE, empty, IS(HTMLTemplateElement));
      ASSERT: typeguard(MODULE, item, IS(HTMLTemplateElement));

      // @ts-ignore template content unknown
      this.#renderEmpty = new Rendering(empty);

      this.#itemTemplate = new Rendering(item);

      ctxPins[CTX_OBSERVE](this, () => {
        this.#warpdrive.disconnect();
        this.#stream.streamReset([]);
        this.replaceChildren();
        this.#warpdrive.connect();
      });
    }

    [disconnectedCallback]() {
      this.#warpdrive.disconnect();
      this.replaceChildren(...this.#transcluded.content.childNodes);
    }
  },
  [ctxPins]
);

/**
 * @typedef {import("@acng/frontend-stargazer").Rendering<{nickname?: string}, {}>} RenderingEmpty
 */
