import {digest} from '@acng/frontend-stargazer';
import {rootRoute} from '@acng/frontend-voyager';
import {watch} from '@acng/frontend-relativity';
import {body, createDiv, removeChildNodes} from '@acng/frontend-bounty';
import {ctxAuthUser} from 'acng/userPool/context/auth-user.js';
import {ctxLocale} from 'acng/locale/model/locale.js';
import {throttle} from '@acng/frontend-bounty/timing/throttle.js';
import {hookFeature} from '../config/feature.js';

/**
 * @param {angular.IRootScopeService} $rootScope
 * @param {angular.ICompileService} $compile
 * @param {angular.ILocationService} $location
 */
export const runBootstrap = ($rootScope, $compile, $location) => {
  ctxAuthUser.subscribeTagName('body');
  ctxLocale.subscribeTagName('body');

  /**
   * @type {angular.IScope}
   */
  let $scope;

  const bootstrap = async () => {
    removeChildNodes(div);
    $scope?.$destroy();
    digest();
    rootRoute.reload($location.path());
    $scope = $rootScope.$new();
    $compile((await hookFeature.render('body')).toElement(div))($scope);
  };

  const div = createDiv();
  body.prepend(div);

  watch(body, throttle(bootstrap, 50, false), ctxAuthUser, ctxLocale);
};
