import {onHashChange, onPopState} from '@acng/frontend-relativity/history';
import {rootRoute} from '@acng/frontend-voyager';

/**
 * @param {import("acng/zz-app").RootScope} $rootScope
 */
export const runRouter = ($rootScope) => {
  $rootScope.request = rootRoute.globals;
  $rootScope.$on('$locationChangeSuccess', (_ev, newURL, oldURL, newState, oldState) => {
    DEBUG: rootRoute.VERBOSE && console.info('$LOCATIONCHANGE', {newURL, newState, oldState});
    if (!newState) {
      DEBUG: rootRoute.VERBOSE &&
        console.info('$LOCATIONCHANGE TRIGGERS relativity HASHCHANGE to polulate HISTORY STATE');
      onHashChange(new HashChangeEvent('hashchange', {newURL, oldURL}));
    } else if (newState !== oldState) {
      DEBUG: rootRoute.VERBOSE &&
        console.info('$LOCATIONCHANGE TRIGGERS relativity POPSTATE to keep the contexts sync');
      onPopState(new PopStateEvent('popstate', {state: newState}));
    }
  });
};
