/**
 * Provide new messages.
 *
 * @module
 */

import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {getGlobalDialog, setGlobalDialog} from './global-dialog.js';
import {listen} from 'acng/core/context/event-bus.js';
import {typeguard, EVENTBUS_MESSAGE_DATA} from '../service/typeguard.js';
import {inject} from 'acng/core/service/ng.js';
import {Message} from '../model/message.js';

const MODULE = 'messenger/context/message';
const VERBOSE = false;

/**
 * New messages sent *by or to* the user.
 *
 * @see {@link ctxSentMessage}
 * @see {@link ctxReceivedMessage}
 *
 * @type {MessageContext}
 */
export const ctxNewMessage = createGlobalContext(null);

/**
 * New Messages sent *by* the user.
 *
 * @type {MessageContext}
 */
export const ctxSentMessage = createGlobalContext(null);

/**
 * New Messages sent *to* the user.
 *
 * @type {MessageContext}
 */
export const ctxReceivedMessage = createGlobalContext(null);

// Provide values to the context objects.
listen('message', async (data) => {
  ASSERT: typeguard(MODULE, data, EVENTBUS_MESSAGE_DATA());

  // Construct message object.
  const partner = await inject('Amateur').get(data.partner.id);
  const message = new Message(partner, data.message);

  DEBUG: if (VERBOSE) console.info(`${MODULE} %s`, message.isMine ? 'sent' : 'received', message);

  // Provide message object via context.
  ctxNewMessage[CTX_PROVIDE](null, message);
  if (message.isMine) {
    ctxSentMessage[CTX_PROVIDE](null, message);

    if (!data.message.fromLivecam) {
      setGlobalDialog(data.partner.id);
    }
  } else {
    ctxReceivedMessage[CTX_PROVIDE](null, message);

    onFirstReceivedMessage?.(partner);
  }
});

/**
 * @type {((partner: import('acng/amateurPool/factory/Amateur').Amateur) => void) | null}
 */
let onFirstReceivedMessage = (partner) => {
  DEBUG: if (VERBOSE) console.info(`${MODULE} handle first received message`, partner);
  onFirstReceivedMessage = null;

  if (!getGlobalDialog() && window.innerWidth >= 768) {
    setGlobalDialog(partner.id);
  }
};

/**
 * Provide a message.
 *
 * @typedef {import('@acng/frontend-relativity').Context<?import('../model/message').Message>} MessageContext
 */
