import {HTMLElement} from '@acng/frontend-bounty';

import {asset} from '../service/env';
import dummySrc from 'assets/basic/img/f_900.png';

import {Tile} from '../widgets/basic-tile.js';

/**
 * @typedef ImageTileConfig
 * @property {() => void} [click]
 * @property {() => boolean} [disable]
 * @property {boolean} [disabled]
 * @property {string} [caption]
 * @property {string} [json]
 * @property {string} [src]
 * @property {string} [format] - used in TeaserTile
 */

/**
 * @typedef ImageTileScope
 * @property {Function} click
 * @property {string} image
 */

export class ImageTile extends Tile {
  /**
   * Switch to disable legacy behaviour, which sets the loaded
   * image as background-style.
   */
  dontSetBg = false;
  /**
   * Resets the tile.
   * @override
   */
  reset() {
    if (this.image) {
      this.image.remove();
    }
    super.reset();
  }
  /**
   * Loads the image into the tile.
   * @override
   * @param {angular.IScope & ImageTileScope} scope - The scope of the tile.
   * @param {ImageTileConfig} config - The configuration for the tile.
   * @returns {Promise<any>} A promise that resolves with the image source.
   */
  async load(scope, config) {
    if (config.click) {
      scope.click = config.click;
    }
    //if (scope.click) {
    if (!(this.box instanceof HTMLElement)) {
      console.warn('ImageTile has no box', this);
      throw Error();
    } else {
      this.box.setAttribute('ng-click', 'click($event)');
    }
    //}
    if (config.disable) {
      if (config.disable()) {
        this.disabled = true;
        throw null;
      }
    }
    if (config.caption) {
      this.caption = config.caption;
    }
    if (config.json) {
      config.src = await this.fetchConfig(config.json);
    }
    this.disabled = this.disabled || config.disabled;
    if (!config.src && !scope.image) {
      return;
    }
    this.image = await new Promise((resolve) => {
      const img = new Image();
      img.src = config.src || scope.image;
      img.onload = () => resolve(img);
      img.onerror = () => {
        this.disabled = true;
        img.src = asset(dummySrc);
        resolve(img);
      };
    });
    this.box.style.cursor = scope.click ? 'pointer' : 'default';
    if (!this.dontSetBg) {
      Object.assign(this.box.style, {
        'height': '100%',
        'background-image': 'url(' + this.image.src + ')',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        'background-position': 'center',
        'cursor': scope.click ? 'pointer' : 'default',
      });
    }
    return this.image.src;
  }
  /**
   * Fetches the image source from a JSON file.
   * @private
   * @param {string} src The URL of the JSON file.
   * @returns {Promise<string>} A promise that resolves with the image source.
   */
  async fetchConfig(src) {
    const {default: get} = await import(src);
    //DEBUG: console.debug('ImageTile fetch src from json', {src, get});
    /** @type {string} */
    const imgSrc = get();
    if (/^http/.test(imgSrc)) {
      return imgSrc;
    }
    return `${src.replace(/[^/]+$/, '')}${imgSrc}`;
  }
  /**
   * Returns the hook name for the tile.
   * @override
   * @returns {string} The hook name.
   */
  hookname() {
    return 'image';
  }
}
