import {
  createAudio,
  addClass,
  removeClass,
  createSpan,
  onClick,
  off,
  append,
  setText,
  isObject,
  document,
  removeChildNodes,
} from '@acng/frontend-bounty';
import {popup} from '@acng/frontend-discovery';
import angular from 'angular';

import {asset} from 'acng/core/service/env';
import imageSrc from '../../../basic/img/kiss.png';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {getIcon} from 'acng/core/service/icon';
import {LAYOUT_CLASS_DISABLED, LAYOUT_CLASS_WAIT} from 'acng/layout/config/css-classes.js';
import {CTX_OBSERVE, CTX_SUBSCRIBE_TAGNAME} from '@acng/frontend-relativity/minify';
import {publishUserMessage} from 'acng/messenger/service/event-bus.js';
import {Message} from 'acng/messenger/model/message';

const ngComponentName = 'onswKissButton';
export default ngComponentName;
export const buttonComponent: angular.IComponentOptions = {
  transclude: true,
  controller: ['$element', '$timeout', 'user', 'http', '$translate', '$transclude', 'payment', controller],
};

ctxAmateur[CTX_SUBSCRIBE_TAGNAME]('onsw-kiss-button');

function controller(
  this: angular.IController, //
  $element: JQLite,
  $timeout: angular.ITimeoutService,
  user: import('acng/userPool/factory/user').User,
  http: core.Http,
  $translate: angular.translate.ITranslateService,
  $transclude: angular.ITranscludeFunction,
  payment: import('acng/payment/factory/payment').Payment
) {
  const element = $element[0];
  addClass(element, 'ons-item');

  function animate(profileId: string) {
    var $target = angular.element('.kissAnimation' + profileId);
    if (!$target) {
      popup(element).info('huba');
      return;
    }

    var $kiss = angular
      .element('<img class="kissImage' + profileId + '">')
      .attr('src', asset(imageSrc))
      .css({
        'position': 'absolute',
        'top': 0,
        'left': 0,
        'width': '100%',
        'height': '100%',
        'object-fit': 'contain',
        'zIndex': 10000,
      });

    $kiss.hide();
    $target.append($kiss);

    var $kissImages = angular.element('.kissImage' + profileId);
    $kissImages.fadeIn();

    $timeout(function () {
      $kissImages.fadeOut(5000);
    }, 2000);

    $timeout(function () {
      $kissImages.remove();
    }, 10000);
  }

  ctxAmateur[CTX_OBSERVE](element, async (amateur, previous) => {
    if (previous) {
      for (const element of document.querySelectorAll(`.kissImage${previous.id}`)) {
        element.remove();
      }
    }

    removeChildNodes(element);
    if (!amateur) {
      return;
    }
    const box = createSpan('box');
    const label = createSpan('label');
    const click = async () => {
      disable();

      if (!amateur.isOnline()) {
        DEBUG: console.debug('kiss/widget/button click offline', {amateur});
        removeClass(box, LAYOUT_CLASS_WAIT);
        addClass(box, LAYOUT_CLASS_DISABLED);
        await popup(box).warn(await $translate('kiss.offline', {nickname: amateur.getNickname()}));
        enable();
        return;
      }

      try {
        await http().post(
          '/api/kiss/send', //
          {id: amateur.id},
          {
            headers: {'Content-Type': 'application/json'}, //
            dontIntercept: true,
          }
        );
        publishUserMessage(
          new Message(amateur, {
            sender: {
              product_id: user.pool_id,
              id: `${user.id}`,
            },
            body: '',
            timestamp_ms: Date.now(),
            payload: {type: 'kiss'},
            attachment: null,
          })
        );
        createAudio('/sounds/kiss.mp3');
        animate(amateur.id);
      } catch (reason: any) {
        // try to detect api response error format
        // @ts-ignore TODO ERROR TYPES
        if (isObject(reason) && isObject(reason.data)) {
          const {
            status,
            data: {code, message},
          } = reason as angular.IHttpResponse<{code: number; message: string}>;
          switch (status) {
            case 400:
              if (code == 5000 || code == 5001) {
                removeClass(box, LAYOUT_CLASS_WAIT);
                addClass(box, LAYOUT_CLASS_DISABLED);
                await popup(box).warn(message);
                return;
              }
              break;
            case 401:
              user.guestSignup('kiss.signupRequired', {nickname: amateur.getNickname()});
              return;
            case 402:
              payment.overlay('kiss.paymentRequired');
              return;
            case 409:
              popup(element).error(reason.data.message);
              return;
          }
        }
        throw reason;
      } finally {
        enable();
      }
    };
    const disable = () => {
      off(box, 'click', click);
      addClass(box, LAYOUT_CLASS_WAIT);
    };
    const enable = () => {
      onClick(box, click);
      removeClass(box, LAYOUT_CLASS_WAIT, LAYOUT_CLASS_DISABLED);
    };
    const [icon, translated] = await Promise.all([
      getIcon('kiss'), //
      $translate('kiss.kissMe'),
    ]);
    $transclude((clone) => clone?.appendTo(box));
    append(box, icon, label);
    onClick(box, click);
    setText(label, translated);
    append(element, box);
  });
}
