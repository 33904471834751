import {createCache} from '@acng/frontend-bounty/cache';
import {inject} from 'acng/core/service/ng.js';
import angular from 'angular';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {listen} from 'acng/core/context/event-bus.js';
import {TRANSACTION} from 'acng/payment/service/event-bus.js';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * @typedef FreeMovieModel
 * @property {number} id
 * @property {number} amateur_id
 * @property {string} day_offset
 * @property {string} free_since
 * @property {string} free_until
 * @property {boolean} use_profile_image
 */

/**
 * @param {angular.IRootScopeService} $rootScope
 */
export const runUpdates = ($rootScope) => {
  $rootScope.$on('userPool.login.success', FreeMovie.updateAccess);
  listen(TRANSACTION, FreeMovie.updateAccess);
};

const cache = createCache();

export class FreeMovie {
  static premiumAccess = false;
  /** @type {Array<FreeMovie>} */
  static items = [];
  /** @type {Array<FreeMovie>} */
  static pastItems = [];
  static loader = null;

  /**
   *
   * @param {FreeMovieModel} data
   */
  constructor(data) {
    this.id = data.id;
    this.amateur_id = data.amateur_id;
    this.free_since = data.free_since;
    this.free_until = data.free_until;
    this.day_offset = parseInt(data.day_offset);
    this.use_profile_image = data.use_profile_image;
    /** @type {import("acng/amateurPool/factory/Amateur").Amateur | null}  */
    this.amateur = null;
    /** @type {import("acng/moviePool/factory/Movie").IMovie | null} */
    this.movie = null;

    const correct = this.getDayOffset();
    if (correct != this.day_offset) {
      console.log(this, this.day_offset, correct);
      this.day_offset = correct;
    }
  }

  checkRoute() {
    return inject('$location').path() === '/fmotd';
  }

  static gotoRoute() {
    inject('$location').path('/fmotd');
  }

  goto() {
    if (this.checkRoute()) {
      this.movie?.goto(true);
    } else {
      FreeMovie.gotoRoute();
    }
  }

  isTeaser() {
    return !this.checkRoute();
  }

  getTimeText() {
    if (this.day_offset < 0) {
      return inject('$translate').instant('moviePool.freemovie.from') + inject('dateFilter')(this.free_since);
    }
    const now = new Date();
    let res = inject('$translate').instant(
      'moviePool.freemovie.next' + (this.day_offset + (now.getHours() < 17 ? 0 : 1))
    );
    if (this.day_offset == 1) {
      res += ' ' + inject('$translate').instant('moviePool.freemovie.time');
    }
    return res;
  }

  getDayOffset() {
    let boundary = dayjs().tz('Europe/Berlin').hour(17).minute(0).second(0).millisecond(0);
    if (dayjs().tz('Europe/Berlin').get('hours') < 17) {
      boundary = boundary.subtract(1, 'day');
    }
    return dayjs(this.free_since).tz('Europe/Berlin').diff(boundary, 'days');
  }

  /**
   *
   * @param {number} id
   * @returns
   */
  static isFree(id) {
    const now = dayjs();
    return this.list()
      .then((l) =>
        l.filter(
          (item) =>
            item.id == id &&
            (FreeMovie.premiumAccess
              ? now.isAfter(item.free_since) && now.isBefore(item.free_until)
              : item.day_offset == 0)
        )
      )
      .then((l) => !!l.length);
  }

  /**
   * @param {number} id
   * @returns {Promise<FreeMovie>}
   */
  static willBeFree(id) {
    const now = dayjs();
    return this.list()
      .then((l) => l.filter((item) => item.id == id && now.isBefore(item.free_since)))
      .then(([freemovie]) => {
        if (!freemovie) throw {msg: 'will not be free', id};
        return freemovie;
      });
  }

  /**
   * @param {number} id
   * @returns {Promise<boolean>}
   */
  static couldBeFree(id) {
    const now = dayjs();
    if (FreeMovie.premiumAccess) {
      return Promise.resolve(false);
    }
    return this.list()
      .then((l) =>
        l.filter(
          (item) =>
            item.id == id &&
            now.isAfter(item.free_since) &&
            now.isBefore(item.free_until) &&
            item.day_offset != 0
        )
      )
      .then((l) => !!l.length);
  }

  /**
   * @returns {Promise<Array<FreeMovie>>}
   */
  static update() {
    return cache(async (setExpire) => {
      const res = await inject('http')().get('/api/movie/free', {dontIntercept: true});
      FreeMovie.items.length = 0;
      FreeMovie.pastItems.length = 0;
      // await await inject('http')()
      //   .get('api/movie/list?limit=50&offset=0&fsk=18')
      //   .then(
      //     /** @param {*} res2 */
      //     (res2) => {
      //       res.data = [];
      //       for (let i = 3; i > -20; i--) {
      //         res.data.push({
      //           id: res2.data.shift(),
      //           day_offset: i,
      //           free_until: dayjs()
      //             .tz('Europe/Berlin')
      //             .hour(17)
      //             .minute(0)
      //             .second(0)
      //             .millisecond(0)
      //             .add(i, 'days')
      //             .toISOString(),
      //           free_since: dayjs()
      //             .tz('Europe/Berlin')
      //             .hour(16)
      //             .minute(59)
      //             .second(59)
      //             .millisecond(999)
      //             .add(i, 'days')
      //             .add(14, 'days')
      //             .toISOString(),
      //           use_profile_image: false,
      //         });
      //       }
      //     }
      //   );

      angular.forEach(res.data, (i) => {
        const freemovie = new FreeMovie(i);
        FreeMovie.items.push(freemovie);
      });
      const match = (res.headers('cache-control') || '').match(/(\d+)/);
      if (match) {
        setExpire(parseInt(match[1]) * 1000);
      }
      return FreeMovie.items;
    });
  }

  static updateAccess() {
    inject('http')()
      .get('/api/movie/free-premium')
      .then((/** @type {{ data: boolean; }} */ res) => (FreeMovie.premiumAccess = res.data));
  }

  static list() {
    return FreeMovie.update();
  }

  static day(i = 0) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    return FreeMovie.update()
      .then((l) => l.find((m) => m.day_offset == i))
      .then((free) => {
        if (!free) return null;

        return (
          inject('Movie')
            .get(free.id)
            .then((movie) => (free.movie = movie))
            // @ts-ignore
            .then(() => inject('Amateur').get(free.movie.user_id))
            .then((amateur) => (free.amateur = amateur))
            .then(() => free)
        );
      });
  }

  static pastMovies() {
    return FreeMovie.update()
      .then((l) => l.filter((m) => m.day_offset < 0)) // && m.free_until > Date.now()))
      .then((l) => {
        return Promise.all(
          l.map((free) => {
            return (
              inject('Movie')
                .get(free.id)
                .then((movie) => (free.movie = movie))
                // @ts-ignore
                .then(() => inject('Amateur').get(free.movie.user_id))
                .then((amateur) => (free.amateur = amateur))
                .catch(() => {})
            );
          })
        ).then(() => l);
      });
  }
}
