import {onClick} from '@acng/frontend-bounty';
import {defineRenderAttribute} from '@acng/frontend-stargazer';

import {coreFeature} from 'acng/core/config/feature.js';
import {post} from 'acng/core/service/backend.js';
import {inject} from 'acng/core/service/ng.js';
import {t} from 'acng/locale/config/translate.js';

const MODULE = 'userPool/controller/logout';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

defineRenderAttribute(':logout', (element) => {
  DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'render', {element});

  const performLogout = async () => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'perform logout', {element});

    // @ts-ignore Notificator
    await inject('Notificator').unsubscribe().catch(console.error);
    await post('user/logout', {}, element);
    inject('user').clear();
  };

  const confirmLogout = async () => {
    DEBUG: if (VERBOSE || element.hasAttribute('debug')) console.info(MODULE, 'click', {element});

    const question = await t('userPool.logout');

    coreFeature.popup(null, 'confirm', (close) => ({question, yes: performLogout, no: () => close()}), true);
  };

  onClick(element, confirmLogout);
});
