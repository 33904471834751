import angular from 'angular';
import {isString} from '@acng/frontend-bounty';
import {getRecentMessage} from '../service/recent-message.js';
import {t} from 'acng/locale/config/translate.js';
import {publish} from 'acng/core/context/event-bus.js';
import {ctxNewMessage} from '../context/message.js';
import {setGlobalDialog} from '../context/global-dialog.js';
import {getDialog} from '../service/dialogs.js';
import {ignoredBy} from 'acng/userPool/context/ignored-by.js';

ctxNewMessage.subscribeClassName('contact-box');

angular.module('messenger').factory('ContactTile', [
  'AmateurTile',
  'user',
  'nicknameFilter',
  'smileyFilter',
  /**
   * @param {unknown} AmateurTile
   * @param {import('acng/userPool/factory/user').User} user
   * @param {unknown} nicknameFilter
   * @param {unknown} smileyFilter
   */
  (AmateurTile, user, nicknameFilter, smileyFilter) => {
    class ContactTile extends AmateurTile {
      /**
       * @param {Scope} scope
       * @param {string | import('../model/dialog').Dialog} item
       */
      load(scope, item) {
        const element = angular.element(this.el);
        /** @type {string} */
        let amateurId;
        /** @type {import('../model/dialog').Dialog} */
        let dialog;
        if (!isString(item)) {
          amateurId = item.id;
          dialog = item;
          scope.closeable = true;
          scope.close = () => publish({type: 'dialog.close', partner: {product_id: 2, id: amateurId}});
        } else {
          amateurId = item;
          dialog = getDialog(item);
          scope.closeable = false;
        }
        scope.dialog = dialog;
        scope.click = () => setGlobalDialog(amateurId);
        scope.imageFormat = '300';
        this.dontSetBg = true;
        return super.load(scope, amateurId)
          .then(/** @param {import('acng/amateurPool/factory/Amateur').Amateur} amateur */ async (amateur) => {

            this.box.prepend(this.image);
            ctxNewMessage.observe(this.box, (message) => {
              if (message?.amateur === amateur) {
                this.setMessage(scope, message);
                scope.$apply();
              }
            });
            scope.$watchGroup(['dialog.open', 'dialog.new'], v => {
              element.toggleClass('active', !!v[0]);
              element.toggleClass('inactive', !v[0]);
              element.toggleClass('unread', !!v[1] && !v[0]);
            });

            if (ignoredBy.has(`2-${amateur.id}`)) {
              t('userPool.ignoredBy', {nickname: amateur.getNickname()}).then((text) => {
                scope.msg = text;
              });
              return;
            }

            return getRecentMessage(amateur)
              .then(msg => {
                if (msg) {
                  this.setMessage(scope, msg);
                } else {
                  if (!isString(item)) {
                    throw 'empty dialog';
                  }
                  return t('messenger.writeMe').then(str => scope.msg = str);
                }
              });
          })
          .catch(/** @param {unknown} err */ (err) => {
            console.warn('ContactTile', 'Removed Amateur #' + amateurId, err);
            throw err;
          });
      }
      /**
       * @param {Scope} scope
       * @param {import('../model/message').Message} msg
       */
      setMessage(scope, msg) {
        scope.msg = '';
        scope.attachment = !!msg.attachment || !!msg.payload.payttachment;
        scope.sent = msg.isMine;
        scope.time = msg.time;
        msg.getMessageText()
          .then(text => scope.msg = smileyFilter(nicknameFilter(text, scope.amateur)))
          .catch(err => console.warn('ContactTile', err));
      }
      css() {
        return ['contact'];
      }
      hookname() {
        return 'contact';
      }
    }
    return ContactTile;
  },
]);

/**
 * @typedef {angular.IScope & {
 *   amateur: import('acng/amateurPool/model/amateur').Amateur;
 *   msg: string;
 *   attachment: boolean;
 *   sent: boolean;
 *   closeable: boolean;
 *   time: import('../model/message').Message["time"];
 *   dialog: import('../model/dialog').Dialog;
 *   imageFormat: string;
 *   close: Function;
 *   click: Function
 * }} Scope
 */
