/**
 * @todo
 * - Collect bounty: createDocumentFragment,
 * - implement more than one game
 * - implement more than one price
 * - implement "transclude" in {@link Widget}
 *
 * @see [Games-Feature](../config/feature.js)
 *
 * @module
 */

import {Widget} from '@acng/frontend-stargazer';
import {removeNode, removeChildNodes, append, setText, cloneNode} from '@acng/frontend-bounty';
import {toggleClass, addClass, removeClass, hide, show} from '@acng/frontend-bounty/style.js';
import {createDiv, createImage, createSpan} from '@acng/frontend-bounty/create.js';
import {onClick, on, off, EVENT_CLICK} from '@acng/frontend-bounty/event.js';
import {connectedCallback, disconnectedCallback} from '@acng/frontend-bounty/dom/custom.js';

import {media} from 'acng/core/service/env.js';
import imageSrc from '../../../basic/img/games/logo-4score.png';
import {t} from 'acng/locale/config/translate.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {ctxOnline} from 'acng/amateurPool/service/online.js';
import {VERBOSE} from 'acng/core/service/debug.js';
import {ctxGameSession} from '../context/session.js';
import {put} from 'acng/core/service/backend';
import {getRunningGame, invite} from '../service/games.js';

const MODULE = 'games/widgets/invite';

const CSS_DISABLED = 'disabled';
const CSS_OFFLINE = 'offline';
const CSS_OCCUPIED = 'occupied';
const CSS_WAIT = 'wait';
const transclude = Symbol();

export class InviteButton extends Widget {
  static consumables = [ctxAmateur, ctxOnline, ctxGameSession];

  static template = false;

  [transclude] = document.createElement('template');

  [connectedCallback]() {
    connect(this);
  }

  [disconnectedCallback]() {
    disconnect(this);
  }
}

/**
 * @param {InviteButton} host
 */
const disconnect = (host) => {
  host.replaceChildren(host[transclude].content);
};

/**
 * @param {InviteButton} host
 */
const connect = (host) => {
  append(host[transclude].content, ...host.childNodes);

  ctxOnline.observe(host, () =>
    ctxAmateur.observe(host, async (amateur) => {
      DEBUG: if (VERBOSE(host)) console.info(MODULE, {amateur});

      /**
       * @type {import('../model/game').State | undefined}
       */
      let state;
      const amateurIsOnline = amateur?.isOnline();
      removeChildNodes(host);
      append(host, host[transclude]);
      removeClass(host, CSS_DISABLED, CSS_WAIT, CSS_OCCUPIED);
      toggleClass(host, CSS_OFFLINE, !amateurIsOnline);
      if (!amateur?.hasGames()) {
        // either no amateur or amateur without games
        addClass(host, CSS_DISABLED);
        return;
      }
      const box = createDiv('box');
      const image = createImage(`${media.assets}/${imageSrc}`);
      const label = createSpan('label');
      const value = createSpan('value');

      const click = async () => {
        DEBUG: if (VERBOSE(host)) console.debug(`${MODULE} click`, {amateur});

        if (!state) {
          addClass(box, CSS_WAIT);
          off(box, EVENT_CLICK, click);
          await invite(amateur).catch(() => {
            removeClass(box, CSS_WAIT);
            on(box, EVENT_CLICK, click);
          });
        } else if (state.userPending) {
          const session = await getRunningGame(amateur.id);
          if (!session) {
            throw Error();
          }
          await put(`games/invitation/${session.id}`, {status: 'accepted'});
        }

        location.hash = `/sedcard/${amateur.nickname}/game`;
      };

      ctxGameSession.observe(host, async () => {
        removeNode(value);
        hide(box);
        state = (await getRunningGame(amateur.id))?.getState();

        DEBUG: if (VERBOSE(host)) console.info(`${MODULE} game state update`, {amateur, state});

        toggleClass(host, CSS_OCCUPIED, !!state);
        if (state) {
          if (state.userPending) {
            setText(label, await t('games.userPending'))
          } else if (state.userTurn) {
            setText(label, await t('games.userTurn'));
          } else {
            setText(label, await t('games.wait', {nickname: amateur.getNickname()}));
          }
        } else if (!amateurIsOnline) {
          setText(label, await t('games.offline', {nickname: amateur.getNickname()}));
        } else {
          setText(label, await t(host.getAttribute('invite-text') ?? 'games.invite'));
          setText(value, '25 Coins');
          append(box, value);
        }
        removeClass(box, CSS_WAIT);
        onClick(box, click);
        show(box);
      });

      // Append elements to box which persist game state updates.
      append(box, image, label);
      append(host, box);
      append(box, cloneNode(host[transclude].content));
    })
  );
};
