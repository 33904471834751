import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {CTX_OBSERVE} from '@acng/frontend-relativity/minify';
import {setText} from '@acng/frontend-bounty';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';

import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {gamesFeature} from '../config/feature.js';
import {getSessions, sessions} from '../service/games.js';
import {createMutex} from '@acng/frontend-bounty/mutex.js';

const MODULE = 'games/widget/user-stats';

(() => {
  defineCustomWidget(
    gamesFeature,
    'onsw-game-user-stats',
    class extends Widget {
      render() {
        ctxAmateur[CTX_OBSERVE](this, async (amateur) => {
          ASSERT: typeguard(
            MODULE,
            this.nodes,
            OBJECT({
              won: IS(Text),
              total: IS(Text),
            })
          );

          const {won, total} = this.nodes;

          setText(won, '?');
          setText(total, '?');

          if (!amateur) {
            return;
          }

          const stats = await mutex(() => getStats(amateur.id));

          setText(won, `${stats.won}`);
          setText(total, `${stats.total}`);
        });
      }
    },
    [ctxAmateur]
  );

  const mutex = createMutex();

  /**
   * @param {string} amateurId - amateur to get the statistics for
   * @returns {Promise<Stats>} Statistics about the games played against this amateur
   * @todo TODO(feature) use "get flag()" instead
   */
  const getStats = async (amateurId) => {
    await getSessions();
    let total = 0;
    let won = 0;
    for (const session of sessions.values()) {
      if (session.amateurId != amateurId) {
        continue;
      }
      const state = session.getState();
      if (state.hasSession) {
        total++;
      }
      if (state.isWon) {
        won++;
      }
    }
    return {total, won};
  };
})();

/**
 * @typedef Stats
 * @property {number} total - Total number of games played against this amateur
 * @property {number} won - Number of games won against this amateur
 */
