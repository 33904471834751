/**
 * Parse `<script id="env">...</script>` from the current `document`,
 * which is provided by {@link ../../../../views/index.blade.php}
 *
 * The values of the variables are configured via the backend. (.env, etc.)
 *
 * Assign types, validate and export all values.
 *
 * @module
 */
import {parseScriptElementAsJson} from '@acng/frontend-bounty/parse';
import {ReferenceError, isUndefined} from '@acng/frontend-bounty/types';
import {BuildInfo} from '../model/build-info.js';
import {AND, ANY, NULLABLE, OBJECT, RECORD, UNKNOWN, typeguard} from '@acng/frontend-bounty/typeguard';
import {AUTH_USER_DATA} from 'acng/userPool/service/typeguard.js';

const MODULE = 'core/service/env';

const ENV_ID = 'env';

/**
 * @deprecated DO NOT USE THIS EXPORT
 */
export const env = parseScriptElementAsJson(ENV_ID);

ASSERT: typeguard(
  'ENV',
  env,
  AND(
    OBJECT({
      BUILD: RECORD(ANY, UNKNOWN),
      authUser: NULLABLE(AUTH_USER_DATA),
    }),
    RECORD(ANY, UNKNOWN)
  )
);

/**
 * @template {string} T
 * @param {T} key -
 * @returns {typeof env[T]} -
 */
const get = (key) => {
  if (isUndefined(env[key])) {
    console.error(`${MODULE} - ´${key}´ not found in ´env´`);
    throw ReferenceError(`${ENV_ID}: ${key}`);
  }

  return env[key];
};

/**
 * @typedef {object} Tmedia
 * @property {object} content -
 * @property {string} content.movies -
 * @property {string} content.pictures -
 * @property {string} content.items -
 * @property {string} assets - Mainly the output of the build process. Plus some other stuff.
 * @property {string} assets_ext - Assets wo do not want to have in the core repository.
 * @property {string} user -
 */
/**
 * Contains base paths for assets and content.
 * TODO explain and cleanup
 */
export const media = /** @type {Tmedia} */ (get('media'));
/**
 * @param {string} src
 */
export const asset = (src) => `${media.assets}/${src}`;

export const FEATURES = /** @type {string[]} */ (get('FEATURES'));
/**
 * @param {string} name
 * @returns {boolean} -
 */
export const hasFeature = (name) => FEATURES.includes(name);

/**
 * TODO explain
 */
export const INHERIT = /** @type {string[]} */ (get('INHERIT'));

/**
 * @typedef Portal
 * @property {number} id -
 * @property {string} name -
 * @property {string} shortcut -
 * @property {boolean} mobile -
 * @property {string} niche -
 * @property {boolean} is_international -
 * @property {object} email -
 * @property {string} email.support -
 * @property {string} email.compliance -
 * @property {string} copyright
 */
/**
 * TODO explain
 */
export const portal = /** @type {Portal} */ (get('portal'));
portal.copyright = `© ${new Date().getFullYear()} ${portal.name}`;

export const hasMyPortal = /** @type {boolean} */ (get('hasMyPortal'));

export const startUpCountry = /** @type {"de" | "en"} */ (get('startUpCountry'));
export const startUpLanguage = /** @type {"de" | "en"} */ (get('startUpLanguage'));
export const startUpFsk = /** @type {"18" | "16" | "12"} */ (get('startUpFsk'));
export const VERSION = /** @type {string} */ (get('VERSION'));
export const API_cdn = /** @type {string} */ (get('API_cdn'));
export const isMobile = /** @type {boolean} */ (get('isMobile'));
export const SONUS = /** @type {string} */ (get('SONUS'));
export const UTM = /** @type {string} */ (get('UTM'));
export const PLAYPAL_STATIC = /** @type {string} */ (get('PLAYPAL_STATIC'));
export const ARCHIMEDES = /** @type {string} */ (get('ARCHIMEDES'));

/**
 * The event bus master **SockJS** URL
 */
export const TROI = /** @type {string} */ (get('TROI'));

/**
 * TODO fill in missing properties
 * @typedef AuthUserData
 * @property {number} id
 * @property {number} pool_id
 * @property {number} partner_pool
 * @property {string} uid
 * @property {string} nickname
 * @property {string} email
 * @property {number} coins
 * @property {number} points
 * @property {number} level
 * @property {Array<{id: number, group: number}>} split_tests
 * @property {"de" | "en"} preferred_language
 */
export const authUser = get('authUser');
export const BUILD = new BuildInfo(env.BUILD);
