import {popup} from '@acng/frontend-discovery';
import {Request, Response, fetch, getHeader} from '@acng/frontend-bounty/fetch';
import {ENUM} from '@acng/frontend-bounty/typeguard.js';

import {POSSIBLE_FSK_LEVELS_DESC, fsk} from 'acng/userPool/fsk.js';
import {API_cdn, VERSION} from './env.js';
import {spinner} from './spinner.js';
import {inject} from './ng.js';
import {Error} from '@acng/frontend-bounty';
import {ERROR_RESPONSE_BODY, typeguard} from './backend/typeguard.js';
import {showXNotifications} from './backend/x-notifications.js';
import {showXUserPopups} from './backend/x-userpopups.js';
import {setOutdated} from './backend/x-version.js';
import {publish} from '../context/event-bus.js';

const MODULE = 'core/service/backend';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

const MIME_JSON = 'application/json';
const MODE_SAME_ORIGIN = 'same-origin';

/**
 * @template T
 * @param {string} path
 * @param {Element} [element]
 * @returns {Promise<T>}
 */
export const cdn = async (path, element) => {
  const url = `${API_cdn}/api/${path}`;
  try {
    const req = new Request(url, {
      mode: 'no-cors',
      headers: {
        //'X-Version': VERSION,
      },
    });
    const res = await fetchResponse(req, element);
    if (!res.ok) {
      throw res;
    }
    return await res.json();
  } catch (reason) {
    console.warn('The CDN Response is not ok - try origin.', {reason});
    return get(path, element);
  }
};

/**
 * @template T
 * @callback ErrorCallback
 * @param {ErrorResponse} error
 * @returns {Promise<T>}
 */

/**
 * @template [T=unknown]
 * @param {string} path
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const get = (path, element, errorCallback) =>
  http(
    new Request(`/api/${path}`, {
      mode: MODE_SAME_ORIGIN,
      headers: {
        'Accept': MIME_JSON,
        //'X-Version': VERSION,
      },
    }),
    element,
    errorCallback
  );

/**
 * @typedef ErrorResponseData
 * @property {number} code
 * @property {string} message
 * @property {{[key: string]: string[]}} [reasons]
 */

/** @implements {ErrorResponseData} */
export class ErrorResponse extends Error {
  /**
   * @param {number} httpStatus
   * @param {ErrorResponseData & {[key: string]: unknown}} data
   */
  constructor(httpStatus, data) {
    super(data.message);
    this.httpStatus = httpStatus;
    this.code = data.code;
    this.reasons = data.reasons;
    this.data = data;
  }
}

/**
 * @todo TODO improve typechecks (make PriceChangeError)
 *
 * @template D
 * @template [T=unknown]
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {D} data
 * @param {Element} [element]
 * @param {ErrorCallback<NoInfer<T>>} [errorCallback]
 * @returns {Promise<T>}
 */
export const post = (path, data, element, errorCallback) =>
  http(
    new Request(`/api/${path}`, {
      mode: MODE_SAME_ORIGIN,
      method: 'POST',
      headers: {
        'Accept': MIME_JSON,
        'Content-Type': MIME_JSON,
        //'X-Version': VERSION,
      },
      body: JSON.stringify(data, null, 2),
    }),
    element,
    async (error) => {
      // TODO REFACTOR PRICE STUFF: move price factory to price-service and make publishPossiblePriceChange(error) or so
      if (error.httpStatus == 409 && error.code >= 9000 && error.code < 10000) {
        // @ts-expect-error
        let id = [9000, 9003].includes(error.data.code) ? data.recipient : /^.*\/(\d+)$/.exec(path)[1];
        let type = {9000: 'msg', 9001: 'movie', 9002: 'picture', 9003: 'msg'};

        /**
         * @type {import('../service/typeguard.js').EventBusPriceChange}
         */
        const busEvent = {
          type: 'price',
          article: {
            article_id: id,
            newPrice: Number(error.data.price),
            type: type[error.code],
          }
        };

        publish(busEvent);

        // @ts-expect-error
        if (data.price > error.data.price) {
          // @ts-expect-error
          data.price = error.data.price;
          return await post(path, data, element, errorCallback);
        }
      }

      if (errorCallback) {
        return await errorCallback(error);
      }

      throw error;
    }
  );

/**
 * @template T,D
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {D} data
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const put = (path, data, element, errorCallback) =>
  http(
    new Request(`/api/${path}`, {
      mode: MODE_SAME_ORIGIN,
      method: 'PUT',
      headers: {
        'Accept': MIME_JSON,
        'Content-Type': MIME_JSON,
        //'X-Version': VERSION,
      },
      body: JSON.stringify(data, null, 2),
    }),
    element,
    errorCallback
  );

/**
 * @template T
 * @throws {Promise<void> | ErrorResponse}
 * @param {string} path
 * @param {HTMLElement} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 * @returns {Promise<T>}
 */
export const httpDelete = (path, element, errorCallback) =>
  http(
    new Request(`/api/${path}`, {
      mode: MODE_SAME_ORIGIN,
      method: 'DELETE',
      headers: {
        'Accept': MIME_JSON,
        //'X-Version': VERSION,
      },
    }),
    element,
    errorCallback
  );

/**
 * Fetch the response for a request and show a loader while fetching.
 *
 * @param {Request} request
 * @param {Element} [element] - Define a context for the loader.
 * @returns Never rejected
 */
const fetchResponse = async (request, element) => {
  try {
    return await spinner(fetch(request), element);
  } catch (reason) {
    console.error('fetch request failed for reason', reason);
    return Response.error();
  }
};

/**
 * @template T
 * @param {Request} request
 * @param {Element} [element]
 * @param {ErrorCallback<T>} [errorCallback]
 */
const http = async (request, element, errorCallback) => {
  const response = await fetchResponse(request, element);

  // TODO Do not `setOutdated` if the `response` might be cached.
  const xVersion = getHeader(response, 'x-version');
  if (xVersion && xVersion != VERSION) {
    setOutdated();
  }

  showXUserPopups(response);
  showXNotifications(response);

  const xFsk = getHeader(response, 'x-fsk');
  if (xFsk) {
    const parsedFskLevel = Number(xFsk);
    DEBUG: if (VERBOSE) console.info(MODULE, 'x-fsk header in response', {response, xFsk, parsedFskLevel});
    ASSERT: typeguard(`${MODULE} parsed x-fsk`, parsedFskLevel, ENUM(POSSIBLE_FSK_LEVELS_DESC));
    fsk.set(parsedFskLevel);
  }

  /**
   * @type {ErrorResponseData}
   */
  let errorData;

  if (response.ok) {
    try {
      return response.status === 204 ? null : await response.json();
    } catch (reason) {
      errorData = {code: -1, message: 'Response decode error'}; // cause: response
    }
  } else if (response.type == 'error') {
    // TODO consider confirm retry
    errorData = {code: -1, message: 'Request error'}; // cause: request
  } else if (response.status === 401) {
    // In case of 401 the response is empty. It is no user logged into the backend session, which would
    // be required for this call.
    errorData = {code: -2, message: 'User is not authenticated'};
  } else {
    // Await generic error data from ACNG api.
    try {
      errorData = await response.json();
      ASSERT: typeguard('ACNGErrorResponse', errorData, ERROR_RESPONSE_BODY);
      // TODO put body into message on exception
    } catch (reason) {
      console.error('Error Response decode error', {response, reason});
      errorData = {code: -1, message: 'Error Response decode error'}; // cause: response
    }
  }

  let error = new ErrorResponse(response.status, errorData); // cause: response

  // Customize catch with error callback
  // TODO consider postprocess for successful response too, this would also provide a return type hint
  if (errorCallback) {
    try {
      return await errorCallback(error);
    } catch (reason) {
      if (reason instanceof ErrorResponse) {
        error = reason;
      } else {
        throw reason;
      }
    }
  }

  if (response.status == 401) {
    const user = inject('user');
    if (!user.guestSignup(error.message)) {
      await popup(element).error(error.message);
      user.clear();
    }
  } else if (response.status == 402) {
    if (error.code == 4001) {
      inject('Payback').openOverlay();
    } else {
      inject('payment').overlay(error.message);
    }
  } else {
    DEBUG: console.error(error);
    await popup(element).error(error.message);
  }

  throw error;
};
