import {Widget, defineCustomWidget} from '@acng/frontend-stargazer';
import {IS, OBJECT, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {ReferenceError, isDefined} from '@acng/frontend-bounty/types';
import {addClass, before, cloneNode, setText, toggleClass, whenAll} from '@acng/frontend-bounty';
import {CTX_OBSERVE, CTX_VALUE} from '@acng/frontend-relativity/minify';

import {inject} from 'acng/core/service/ng.js';
import {getIcon} from 'acng/core/service/icon.js';
import {ctxAmateur} from 'acng/amateurPool/context/amateur.js';
import {ctxOnline} from 'acng/amateurPool/service/online.js';
import {LAYOUT_CLASS_ACTIVE, LAYOUT_CLASS_INACTIVE} from 'acng/layout/config/css-classes.js';

import {messengerFeature} from '../config/feature.js';
import {setGlobalDialog} from '../context/global-dialog.js';

const MODULE = 'messenger/widget/open-dialog';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @module
 *
 * @example
 * ```html
 * <onsw-open-dialog-button data-icon="chatColor"></onsw-open-dialog-button>
 * ```
 */

/**
 * Only these icons are possible for the `data-icon` attribute. 'chat' is the default.
 */
const PRELOAD_ICONS = ['chat', 'chatColor'];

/**
 * A set of possible icons will be preloaded by Widget.setup into that.
 * @type {Record<string, SVGSVGElement>}
 */
const icons = {};

/**
 * @typedef TextNodes
 *
 * @property {Text} nickname
 * The `nickname` ist included from the `messenger.chatWith` translation.
 */

/**
 * @typedef Elements
 *
 * @property {HTMLElement} label
 * The `label` is used to insert an icon before.
 */

defineCustomWidget(
  messengerFeature,
  'onsw-open-dialog-button',
  class extends Widget {

    static async setup() {
      const loadIcons = await whenAll(PRELOAD_ICONS.map((name) => getIcon(name)));
      icons.chat = loadIcons[0];
      icons.chatColor = loadIcons[1];
    }

    static consumables = [ctxAmateur, ctxOnline];

    render() {
      ASSERT: typeguard('Template', this.nodes, OBJECT({
        label: IS(HTMLElement),
        nickname: IS(Text),
      }));

      const {label, nickname} = this.nodes;

      addClass(this, 'ons-item');
      before(label, cloneNode(icons[this.dataset.icon || 'chat']));

      ctxAmateur[CTX_OBSERVE](this, (amateur) => {
        DEBUG: if (VERBOSE) console.info(MODULE, {amateur});

        setText(nickname, amateur?.getNickname() ?? null);

        ctxOnline[CTX_OBSERVE](this, () => {
          const isOnline = amateur?.isOnline();
          toggleClass(this, LAYOUT_CLASS_ACTIVE, isOnline);
          toggleClass(this, LAYOUT_CLASS_INACTIVE, !isOnline);
        });
      });
    }

    click() {
      if (isDefined(this.dataset.passive)) {
        return;
      }
      const amateur = ctxAmateur[CTX_VALUE](this);
      if (!amateur) {
        throw ReferenceError();
      }
      if (inject('user').guestSignup('messenger.signupRequired', {nickname: amateur.getNickname()})) {
        return;
      }
      setGlobalDialog(amateur.id);
    }
  }
);
