import {Promise, isUndefined} from '@acng/frontend-bounty';
import {BOOLEAN, ENUM, INTEGER, OBJECT, STRING, typeguard} from '@acng/frontend-bounty/typeguard.js';
import {createGlobalContext} from '@acng/frontend-relativity';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

import {listen} from 'acng/core/context/event-bus.js';
import {TIMESTAMP_MS} from 'acng/core/service/typeguard.js';
import {Amateur} from 'acng/amateurPool/model/amateur';

const MODULE = 'pin/service';

export const ctxPins = createGlobalContext(false);

/**
 * @deprecated Use byAmateur instead
 * @type {string[] & {changed?: number}}
 */
const pins = [];

/**
 * @type {Set<string>}
 */
export const byAmateur = new Set();
/**
 * @deprecated Implement something different
 * @type {(res: typeof pins) => void}
 */
let resolvePins;

/**
 * @deprecated
 * Required for the onsd-thing
 * @type {Promise<typeof pins>}
 */
export const promise = new Promise((resolve) => {
  resolvePins = () => resolve(pins);
});

listen('pin', (data) => {
  ASSERT: typeguard(MODULE, data, EVENTBUS_PIN);
  const id = `${data.partner.id}`;

  if (isUndefined(pins.changed)) {
    pins.changed = 0;
  }

  if (data.status) {
    if (pins.indexOf(id) < 0) {
      pins.unshift(id);
      pins.changed++;
      byAmateur.add(id);
      ctxPins[CTX_PROVIDE](null, true);
    }
  } else {
    const idx = pins.indexOf(id);
    if (idx >= 0) {
      pins.splice(idx, 1);
      pins.changed++;
      byAmateur.delete(id);
      ctxPins[CTX_PROVIDE](null, true);
    }
  }
});

/**
 * @param {Amateur} amateur
 */
export const checkPin = (amateur) => byAmateur.has(amateur.id);

/**
 * @param {string} amateurId
 */
export const checkPinById = (amateurId) => byAmateur.has(amateurId);

/**
 * @deprecated Workaround for ../userPool/context/ignored-by.js
 */
export const clearPins = () => {
  pins.length = 0;
  if (isUndefined(pins.changed)) {
    pins.changed = 0;
  }
  pins.changed++;
  byAmateur.clear();
};

/**
 * @deprecated Workaround for ../userPool/context/ignored-by.js
 * @param {string[]} ids
 */
export const setPins = (ids) => {
  for (const id of ids) {
    byAmateur.add(id);
    pins.push(id);
  }
  resolvePins(pins);
  console.warn('pin provide');
  ctxPins.provide(null, true);
};

const EVENTBUS_PIN = /* @__PURE__ */ (() =>
  OBJECT({
    type: ENUM(/** @type {const} */ (['pin'])),
    partner: OBJECT({pool_id: INTEGER, id: STRING}),
    status: BOOLEAN,
    timestamp_ms: TIMESTAMP_MS(),
  }))();
