/**
 * @module Game-Updates
 * @since 3.80.0
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {createGlobalContext} from '@acng/frontend-relativity';
import {Session} from '../model/game.js';
import {CTX_PROVIDE} from '@acng/frontend-relativity/minify';

const MODULE = 'games/context/session';
const VERBOSE = false;
DEBUG: if (VERBOSE) console.warn('Import verbose', MODULE);

/**
 * @type {import("@acng/frontend-relativity").Context<?Session>}
 */
export const ctxGameSession = createGlobalContext(null);

ctxGameSession[CTX_PROVIDE](null, null);

/**
 * @param {?Session} session
 * @returns {void}
 */
export const dispatch = (session) => {
  DEBUG: if (VERBOSE) console.info(MODULE, {session});
  ctxGameSession[CTX_PROVIDE](null, session);
};
